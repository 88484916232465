import { useEffect } from "react";
import { useActiveUserPermissions } from "hooks/useRoles";

import AdminRoles from "./AdminRoles";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import PasswordFields from "components/PasswordFields/PasswordFields";

const AddEditAdminForm = ({
  object,
  setObject,
  adminRole,
  setAdminRole,
  submitResource,
  formState,
  loading,
  emailExists = false,
}: any) => {
  const currentuserPermissions = useActiveUserPermissions();

  const handleChange = (input: any) => {
    let tempObj: any = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  useEffect(() => {
    let tempObj = { ...object };
    tempObj.super = adminRole === "superAdmin" ? true : false;
    setObject(tempObj);
  }, [adminRole]);

  useEffect(() => {
    if (object?.password === "" && object?.confirmPassword === "") {
      let tempObj: any = { ...object };
      delete tempObj["password"];
      delete tempObj["confirmPassword"];
      setObject(tempObj);
    }
  }, [object?.password, object?.confirmPassword]);

  return (
    <form onSubmit={submitResource}>
      <TextField
        fullWidth
        required
        label="Email"
        type="email"
        name="email"
        value={object ? object.email : ""}
        onChange={(e) => handleChange(e.target)}
        className="mt-2"
        error={emailExists}
        helperText={emailExists && "Email is already in use"}
      />
      <PasswordFields
        object={object}
        setObject={setObject}
        formState={formState}
      />
      <div className="mt-3 d-flex">
        <AdminRoles
          object={object}
          adminRole={adminRole}
          setAdminRole={setAdminRole}
        />
      </div>

      {currentuserPermissions?.includes("edit-admin") && (
        <Button
          variant="contained"
          sx={{ color: "white", mt: "30px", width: "100%" }}
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="1rem" />
          ) : formState === "create" ? (
            "Add Admin"
          ) : (
            "Update Admin"
          )}
        </Button>
      )}
    </form>
  );
};

export default AddEditAdminForm;
