import { useState } from "react";
import { useHistory } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";

import categories from "configs/categories";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";

import Box from "@mui/material/Box";

import placeholderPic from "../../../assets/no_data_x.gif";
import { useApiHook } from "providers/ApiProvider";

const CategoryTableView = () => {
  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);

  const [queryParams, setQueryParams] = useUrlState({
    // q: undefined,
    page: 0,
    size: 10,
    // sortBy: "created_at",
    // sortDirection: "desc",
  });

  // const [searchQuery, setSearchQuery] = useState(
  //   localStorage.getItem("searchQuery") || ""
  // );

  // const permissionChecker = usePermissionChecker();

  const query = {
    // q: searchQuery ?? "",
    page: queryParams.page,
    size: queryParams.size,
    // _sort: queryParams.sortBy ?? "created_at",
    // _order: queryParams.sortDirection ?? "desc",
  };

  const categoryData = useApiHook("Categories", "getAllResources", { query });

  // const handleSearch = (value: string) => {
  //   localStorage.setItem("searchQuery", value);
  //   setSearchQuery(value);

  //   if (queryParams.q !== value) {
  //     setQueryParams({ q: value, page: 0 });
  //   } else {
  //     setQueryParams({ q: value });
  //   }
  // };

  // const deleteCategory = () => {
  //   if (!permissionChecker("delete-category", true)) return;

  //   setLoading(true);

  //   categoriesClient
  //     .deleteManyResource({ data: selectedRows })
  //     .then(() => categoryData.refresh())
  //     .finally(() => setLoading(false));
  // };

  const navigateToAddPage = () => {
    history.push(`categories/add`);
  };

  const rowTriggerAction = (row: any) => {
    history.push(`categories/${row?.id}`);
  };

  const handlePageChange = (e: any, value: any) => {
    setQueryParams({ page: value });
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setQueryParams({ page: 0, size: parseInt(e.target.value) });
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  // const onColumnSort = (c: any) => {
  //   if (queryParams.sortBy === c) {
  //     setQueryParams((s) => ({
  //       sortDirection: s.sortDirection === "asc" ? "desc" : "asc",
  //     }));
  //   } else {
  //     setQueryParams({ sortBy: c, sortDirection: "asc" });
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        // onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        // deleteResourceHandler={deleteCategory}
        selectedRows={selectedRows}
        model="category"
      />
      <div className="p-4">
        <h2 className="mb-4">Categories</h2>
        <GenericTable
          showCheckbox={false}
          frontendPagination={true}
          list={categoryData?.data?.data ? categoryData?.data?.data : []}
          totalCount={categoryData?.data?.data?.length || 0}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={Number(queryParams.page)}
          loading={categoryData.loading}
          rowsPerPage={Number(queryParams.size)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={categories}
          model="category"
          passRow={rowTriggerAction}
          // columnSort={queryParams.sortBy}
          // columnSortDir={queryParams.sortDirection}
          // onColumnSort={onColumnSort}
        />
      </div>
    </Box>
  );
};

export default CategoryTableView;
