import useScreenSize from "hooks/useScreenSize";

import VariantImages from "./VariantImages";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";

import { FaChevronDown, FaPlus, FaTrash } from "react-icons/fa6";
import ColorPattern from "./ColorPattern";
import getVariantBackground from "lib/getVariantBackground";

const ProductVariants = ({
  object,
  setObject,
  colorNames,
  handleChange,
  handleColorCodeChange,
  addVariant,
  deleteVariant,
  removeImage,
}: any) => {
  const device = useScreenSize();

  const colorOrImage = (input: any) => {
    if (input.includes("url")) {
      return "image";
    } else {
      return "color";
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Typography
          variant="h4"
          color="secondary"
          sx={{ marginBottom: "0 !important" }}
        >
          Variants
        </Typography>
        {device === "mobile" ? (
          <Button
            variant="outlined"
            onClick={() => {
              addVariant(object?.variants?.length);
            }}
          >
            <FaPlus style={{ width: "15px", height: "15px" }} />
          </Button>
        ) : (
          <Button
            variant="outlined"
            startIcon={<FaPlus style={{ width: "15px", height: "15px" }} />}
            onClick={() => {
              addVariant(object?.variants?.length);
            }}
          >
            Add Variant
          </Button>
        )}
      </Box>

      {!(object.variants.length > 0) && (
        <Typography variant="h5">No variant exists</Typography>
      )}

      {object.variants.map((variant: any, index: number) => {
        return (
          <Accordion className="variantAccordion" key={index}>
            <AccordionSummary
              sx={{ background: "#e7e6e6" }}
              expandIcon={<FaChevronDown />}
              aria-controls={`variant-${1}-content`}
              id={`variant-${index}-header`}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  background:
                    getVariantBackground(variant.colorCss) +
                      " center center / cover" ?? "#fff",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              ></Box>
              <Typography>
                {variant?.colorCode
                  ? `${variant.colorCode}/${
                      colorNames?.find((obj) => obj.key === variant.colorCode)
                        ?.value || ""
                    }`
                  : variant.variantName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ marginTop: "20px" }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    // required
                    fullWidth
                    label="Variant ID *"
                    name="id"
                    value={variant ? variant.id : ""}
                    onChange={(e) => handleChange(e.target, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // required
                    fullWidth
                    label="Variant Name *"
                    name="variantName"
                    value={variant ? variant.variantName : ""}
                    onChange={(e) => handleChange(e.target, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    // required
                    fullWidth
                    label="Model Code *"
                    name="modelCode"
                    value={variant ? variant.modelCode : ""}
                    onChange={(e) => handleChange(e.target, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    // required
                    fullWidth
                    type="number"
                    label="Stock Value *"
                    name="stockValue"
                    value={variant ? variant.stockValue : 0}
                    onChange={(e) => handleChange(e.target, index)}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Lens Description"
                    name="lensDescription"
                    value={object ? object.variants[index].lensDescription : ""}
                    onChange={(e) => handleChange(e.target, index)}
                  />
                </Grid> */}
              </Grid>

              {/* Variant Styles */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  color="secondary"
                  gutterBottom
                  sx={{ textTransform: "uppercase" }}
                >
                  Variant Style
                </Typography>

                <Autocomplete
                  sx={{ width: 300, mb: "20px" }}
                  options={colorNames ?? []}
                  getOptionLabel={(option: any) =>
                    `${option.key} - ${option.value}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Color Code"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.key} - {option.value}
                    </li>
                  )}
                  value={
                    (variant
                      ? colorNames?.find(
                          (option) => option.key === variant.colorCode
                        )
                      : "") ?? null
                  }
                  onChange={(event, newValue) =>
                    handleColorCodeChange(index)(event, newValue)
                  }
                />

                <ColorPattern
                  object={object}
                  setObject={setObject}
                  colorOrImage={colorOrImage}
                  index={index}
                  handleChange={handleChange}
                />
              </Box>

              {/* Variant Specification */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  color="secondary"
                  gutterBottom
                  sx={{ textTransform: "uppercase" }}
                >
                  Variant Specification
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      // required
                      fullWidth
                      type="number"
                      label="Lens Height *"
                      name="lensHeight"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            mm
                          </InputAdornment>
                        ),
                      }}
                      value={variant ? variant.specifications.lensHeight : ""}
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Lens Width (Caliber)"
                      name="lensWidth"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            mm
                          </InputAdornment>
                        ),
                      }}
                      value={variant ? variant.specifications.lensWidth : ""}
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      // required
                      fullWidth
                      type="number"
                      label="Lens Diagonal *"
                      name="lensDiagonal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            mm
                          </InputAdornment>
                        ),
                      }}
                      value={variant ? variant.specifications.lensDiagonal : ""}
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>

                  <Grid item xs={3}></Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      // required
                      fullWidth
                      label="Lens Material *"
                      name="lensMaterial"
                      value={variant ? variant.specifications.lensMaterial : ""}
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      // required
                      fullWidth
                      type="number"
                      label="Bridge Length *"
                      name="bridgeNodeLengthSize"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            mm
                          </InputAdornment>
                        ),
                      }}
                      value={
                        variant
                          ? variant.specifications.bridgeNodeLengthSize
                          : ""
                      }
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      // required
                      fullWidth
                      type="number"
                      label="Temple Length *"
                      name="templeLengthSize"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            mm
                          </InputAdornment>
                        ),
                      }}
                      value={
                        variant ? variant.specifications.templeLengthSize : ""
                      }
                      onChange={(e) => handleChange(e.target, index, true)}
                    />
                  </Grid>
                </Grid>

                <VariantImages
                  object={object}
                  setObject={setObject}
                  imageLinks={object.variants[index].imageLinks}
                  index={index}
                  removeImage={removeImage}
                />
              </Box>
              {object?.variants?.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={
                      <FaTrash style={{ width: "15px", height: "15px" }} />
                    }
                    onClick={() => deleteVariant(index)}
                  >
                    Remove Variant
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default ProductVariants;
