import { useEffect } from "react";
import { useActiveUserPermissions } from "hooks/useRoles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import PasswordFields from "components/PasswordFields/PasswordFields";

const AddEditCustomerForm = ({
  object,
  setObject,
  submitResource,
  formState,
  loading,
}: any) => {
  const currentuserPermissions = useActiveUserPermissions();

  const handleChange = (e: any) => {
    let tempObj: any = { ...object };

    if (e.target.name === "status") {
      tempObj["status"] = e.target.checked ? "active" : "inactive";
    } else {
      tempObj[e.target.name] = e.target.value;
    }

    setObject({ ...tempObj });
  };

  const handleAddressChange = (e: any) => {
    let tempObj: any = { ...object };

    tempObj["address"][e.target.name] = e.target.value;

    setObject({ ...tempObj });
  };

  useEffect(() => {
    if (object?.password === "" && object?.confirmPassword === "") {
      let tempObj: any = { ...object };
      delete tempObj["password"];
      delete tempObj["confirmPassword"];
      setObject(tempObj);
    }
  }, [object?.password, object?.confirmPassword]);

  return (
    <form className="add-customer-form" onSubmit={submitResource}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  name="status"
                  checked={object?.status === "active"}
                  onChange={(e) => handleChange(e)}
                />
              }
              label="Active"
              labelPlacement="start"
              sx={{ ml: 0 }}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h4"
          color="secondary"
          gutterBottom
          sx={{ textTransform: "uppercase" }}
        >
          Personal Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Full Name"
              name="fullName"
              value={object ? object.fullName : ""}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              type="email"
              label="Email"
              name="email"
              value={object ? object.email : ""}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={object ? object.phoneNumber : ""}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h4"
          color="secondary"
          gutterBottom
          sx={{ textTransform: "uppercase" }}
        >
          Address Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 1"
              name="addressLine1"
              value={object ? object.address.addressLine1 : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="addressLine2"
              value={object ? object.address.addressLine2 : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={object ? object.address.city : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={object ? object.address.country : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="State"
              name="state"
              value={object ? object.address.state : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Postal Code"
              name="postalCode"
              value={object ? object.address.postalCode : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={object ? object.address.phoneNumber : ""}
              onChange={(e) => handleAddressChange(e)}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h4"
          color="secondary"
          gutterBottom
          sx={{ textTransform: "uppercase" }}
        >
          Security
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordFields
              object={object}
              setObject={setObject}
              formState={formState}
            />
          </Grid>
        </Grid>

        {currentuserPermissions?.includes("edit-customer") && (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ color: "white", mt: "30px", width: "100%" }}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size="1rem" />
                ) : formState === "create" ? (
                  "Add Customer"
                ) : (
                  "Update Customer"
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </form>
  );
};

export default AddEditCustomerForm;
