import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { SidebarItem } from "../../interfaces/SidebarItem";
import { logout } from "../../store/actions/session";
import { ConfirmDialog } from "../Dialogs";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

import {
  FaBars,
  FaCartShopping,
  FaRibbon,
  FaUserShield,
  FaGlasses,
  FaUsers,
  FaFolderTree,
  FaRightFromBracket,
} from "react-icons/fa6";

import "./MobileMenu.scss";
import useUserData from "hooks/useUserData";

const StyledMobileMenu = styled(Box)`
  background-color: #132034;

  display: flex;
  flex-wrap: wrap;

  .menuButton {
    margin-right: ${({ theme }) => theme.spacing(2)};
    color: #fff;
    padding: 0;
    margin: 0;
  }

  .menuList {
    width: 100%;
    height: 100%;
    list-style: none;
    background-color: #132034;
    transition: 0.5s all;
  }

  .menuListItem {
    color: #fff;
    margin: 10px 0px;
    padding: 10px;
    width: 100%;
    background-color: rgba(255 255 255 / 0.05);
  }

  .title {
    flex-grow: 1;
  }

  .logo {
    height: 3em;
    display: inline-block;
    margin-left: auto;
  }

  .MuiCollapse-root {
    flex: 1 0 100%;
  }
`;

const MobileMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useUserData();

  const [menu, setMenu] = useState(false);

  const initConfirmConfig = {
    title: "",
    body: "",
    visible: false,
  };

  const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

  const regularRoutes = [
    {
      path: "/orders",
      label: "Orders",
      routeActiveHandler: "orders",
      icon: FaCartShopping,
    },
    {
      path: "/brands",
      label: "Brands",
      routeActiveHandler: "brands",
      icon: FaRibbon,
    },
    {
      path: "/categories",
      label: "Categories",
      routeActiveHandler: "categories",
      icon: FaFolderTree,
    },
    {
      path: "/products",
      label: "Products",
      routeActiveHandler: "products",
      icon: FaGlasses,
    },
    {
      path: "/customers",
      label: "Customers",
      routeActiveHandler: "customers",
      icon: FaUsers,
    },
  ];

  const adminRoutes = [
    ...regularRoutes,
    {
      path: "/admins",
      label: "Admins",
      routeActiveHandler: "admins",
      icon: FaUserShield,
    },
  ];

  const routes: SidebarItem[] = useMemo(() => {
    if (user?.super === true) {
      return adminRoutes;
    }

    return regularRoutes;
  }, [user?.super]);

  const confirmLogout = () => {
    setConfirmConfig({
      title: "Please Confirm",
      body: "Are you sure you want to log out?",
      visible: true,
    });
  };

  const navigateTo = (route: string) => {
    history.push(route);
    setMenu(false);
  };

  const logoutUser = () => {
    dispatch(logout());
  };
  return (
    <>
      <ConfirmDialog
        confirmConfig={confirmConfig}
        executeActionCallback={logoutUser}
        setConfirmConfig={setConfirmConfig}
      />
      <StyledMobileMenu className={" p-3"}>
        <IconButton
          onClick={() => setMenu(!menu)}
          className={"menuButton"}
          aria-label="menu"
        >
          <FaBars />
        </IconButton>

        <img src="/LogoLight.svg" className="logo" />
        <Collapse in={menu}>
          <Box
            className={"menuList"}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <List className={"menuList"}>
              {routes.map((route, index) => (
                <ListItem
                  key={route.path || index}
                  className={"menuListItem"}
                  onClick={() => navigateTo(route.path)}
                >
                  {route.icon ? (
                    <route.icon style={{ marginRight: "20px" }} />
                  ) : (
                    ""
                  )}
                  <Typography variant="body1">{route.label}</Typography>
                </ListItem>
              ))}
              <ListItem
                className={"menuListItem"}
                onClick={() => confirmLogout()}
              >
                <FaRightFromBracket style={{ marginRight: "20px" }} />
                <Typography variant="body1">Log Out</Typography>
              </ListItem>
            </List>
          </Box>
        </Collapse>
      </StyledMobileMenu>
    </>
  );
};

export default MobileMenu;
