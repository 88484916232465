import ApiRequests from "../apiRequests";

class AdminService extends ApiRequests {
  constructor() {
    super("admin");
  }

  async signout(): Promise<any> {
    return await this.doRequest(`${this.baseUrl}/admin/auth/signout`, "GET");
  }
}

export default AdminService;
