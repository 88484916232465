import { TableConfig } from "interfaces/tableConfig";

const products: TableConfig[] = [
  {
    heading: "ID",
    attribute: "id",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Model",
    attribute: "modelName",
    type: "string",
    sortable: false,
    selected: true,
  },
  {
    heading: "Brand",
    attribute: "brandName",
    type: "string",
    sortable: false,
    selected: true,
  },
  {
    heading: "Release Date",
    attribute: "releaseDate",
    type: "date",
    sortable: true,
    selected: true,
  },
  {
    heading: "Weight",
    attribute: "weight",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Weight Unit",
    attribute: "weightUnit",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Price",
    attribute: "price",
    type: "currency",
    sortable: true,
    selected: true,
  },
  {
    heading: "Spec Segment",
    attribute: "specSegment",
    type: "string",
    sortable: false,
    selected: false,
  },
  {
    heading: "Spec Type",
    attribute: "specType",
    type: "string",
    sortable: false,
    selected: false,
  },
];

export default products;
