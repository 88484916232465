import { useMemo, useState, useEffect } from "react";
import dayjs from "dayjs";

import { useActiveUserPermissions, usePermissionChecker } from "hooks/useRoles";
import NoResourceAvailable from "../NoResourceAvailable/NoResourceAvailable";
import TableSortLabel from "@mui/material/TableSortLabel";
import ColumnOptions from "./ColumnOptions";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

import {
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaCheck,
  FaXmark,
  FaPen,
  FaEye,
} from "react-icons/fa6";

import "./GenericTable.scss";

export interface GenericTableParams<T = any> {
  list: T[];
  totalCount: number;
  selectedRows: any;
  page: number;
  onSelectedChange: any;
  rowsPerPage: number;
  onRowsChange: any;
  onPageChange: any;
  model: string;
  passRow: any;
  image: string;
  tableConfig: any;
  loading: boolean;
  hideActionButton?: boolean;
  showCheckbox?: boolean;
  frontendPagination?: boolean;
  getRowProps?: (row: T, index: number) => TableRowProps;
  columnSort?: any;
  columnSortDir?: "asc" | "desc";
  onColumnSort?: (c: any) => any;
}

const GenericTable = <T = any,>(params: GenericTableParams<T>) => {
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const currentUserPermissions = useActiveUserPermissions();

  const storedTableConfig = localStorage.getItem(params.model + "TableConfig");
  const initialTableConfig = storedTableConfig
    ? JSON.parse(storedTableConfig)
    : params.tableConfig || [];
  const [tableConfig, setTableConfig] = useState(initialTableConfig);

  const navigate = (row: any) => {
    params.passRow(row);
  };

  const renderFiles = (row: any, object: any): any => {
    let data: any = row[object.attribute];
    let max_files: number = 2;

    if (Array.isArray(data)) {
      return (
        <div>
          {data.length == 0
            ? "none"
            : data.map((file, index) =>
                index >= max_files ? (
                  ""
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    key={file.url}
                    href={file.url}
                  >
                    {file.type === "image" ? (
                      <FaFileImage className="fontAwesomeIcon" />
                    ) : (
                      <FaFile className="fontAwesomeIcon" />
                    )}
                  </a>
                )
              )}
          <>{data.length > max_files ? "..." : ""}</>
        </div>
      );
    } else {
      return "not a files array...";
    }
  };

  const handleSelectionChange = (rowId: any) => {
    let data = [...params.selectedRows];
    if (data.includes(rowId)) {
      data = data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    params.onSelectedChange(data);
  };
  const selectAll = (checked: any) => {
    let dataList = [...params.selectedRows];
    if (checked) {
      params.list.forEach((item: any) => {
        // if (!dataList.includes(item.id)) {
        //   dataList.push(item.id);
        // }
        if (
          !dataList.includes(params.model === "brand" ? item.code : item.id)
        ) {
          dataList.push(params.model === "brand" ? item.code : item.id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      params.list.forEach((item: any) => {
        // dataList = dataList.filter((listItem) => listItem !== item?.id);
        dataList = dataList.filter(
          (listItem) =>
            listItem !== (params.model === "brand" ? item?.code : item?.id)
        );
      });
      setSelected(dataList);
    }
    params.onSelectedChange(dataList);
  };

  const isSelectedAll = useMemo(() => {
    if (params.selectedRows?.length === 0) return false;

    // // if the selected and the list sizes don't match, then obviously not all are selected
    // if (params.selectedRows?.length !== params.list?.length) return false;

    return params.list?.every((item) =>
      // params.selectedRows.includes(item["id"])
      params.selectedRows.includes(
        params.model === "brand" ? item["code"] : item["id"]
      )
    );
  }, [params.selectedRows, params.list]);

  const list = useMemo(() => {
    if (params.frontendPagination) {
      return params.list?.slice(
        params.page * params.rowsPerPage,
        params.page * params.rowsPerPage + params.rowsPerPage
      );
    } else {
      return params.list || [];
    }
  }, [params.frontendPagination, params.list, params.page, params.rowsPerPage]);

  if (loading || params.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box
      alignItems="flex-start"
      className="usersList w-100"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <>
        <TableContainer component={Paper} className="tableContainer">
          <Table className="usersListTable">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {params.showCheckbox !== false && (
                    <Checkbox
                      className="checkBox"
                      style={{
                        color: "#fff",
                      }}
                      onChange={(e) => selectAll(e.target.checked)}
                      checked={isSelectedAll}
                    />
                  )}
                </TableCell>
                {tableConfig.map((row: any, index: number) => {
                  if (row.selected) {
                    return (
                      <TableCell
                        key={row.attribute || index}
                        align="left"
                        sortDirection={
                          row.attribute === params.columnSort
                            ? params.columnSortDir
                            : false
                        }
                      >
                        {row.sortable && params.columnSort ? (
                          <>
                            <TableSortLabel
                              className="sortLabel"
                              active={row.attribute === params.columnSort}
                              direction={
                                row.attribute === params.columnSort
                                  ? params.columnSortDir
                                  : "asc"
                              }
                              onClick={() =>
                                params.onColumnSort?.(row.attribute)
                              }
                            >
                              {row.heading}
                            </TableSortLabel>
                          </>
                        ) : (
                          <>{row.heading}</>
                        )}
                      </TableCell>
                    );
                  }
                })}
                <TableCell align="right">
                  <ColumnOptions
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                    model={params.model}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            {params.totalCount > 0 && !params.loading && (
              <TableBody>
                {list.map((row: any, index: number) => (
                  <TableRow
                    // key={row.id || index}
                    key={
                      (params.model === "brand" ? row.code : row.id) || index
                    }
                    {...params.getRowProps?.(row, index)}
                  >
                    <TableCell align="left">
                      {params.showCheckbox !== false && (
                        <Checkbox
                          className="checkBox"
                          color="primary"
                          // value={row.id ? row.id : ""}
                          // checked={params.selectedRows.includes(row.id)}
                          // onChange={(e) => handleSelectionChange(row.id)}
                          value={
                            params.model === "brand"
                              ? row.code
                                ? row.code
                                : ""
                              : row.id
                              ? row.id
                              : ""
                          }
                          checked={params.selectedRows.includes(
                            params.model === "brand" ? row.code : row.id
                          )}
                          onChange={(e) =>
                            handleSelectionChange(
                              params.model === "brand" ? row.code : row.id
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      )}
                    </TableCell>
                    {tableConfig.map((object: any, index: number) => {
                      if (object.selected) {
                        return (
                          <TableCell
                            // key={(row.id || index) + object.attribute}
                            key={
                              ((params.model === "brand" ? row.code : row.id) ||
                                index) + object.attribute
                            }
                            className="pointer"
                            align="left"
                          >
                            {/* if column is date, display formatted date */}
                            {object.type === "date" &&
                              (dayjs(row[object.attribute]).isValid() &&
                              dayjs(row[object.attribute]).isAfter(0)
                                ? dayjs(row[object.attribute]).format(
                                    "MM/DD/YYYY"
                                  )
                                : "none")}
                            {object.type === "datetime" &&
                              (dayjs(row[object.attribute]).isValid() &&
                              dayjs(row[object.attribute]).isAfter(0)
                                ? dayjs(row[object.attribute]).format(
                                    "MM/DD/YYYY hh:mm a"
                                  )
                                : "--")}
                            {/*  */}
                            {object.type === "boolean" &&
                              (row[object.attribute] == true ? (
                                <FaCheck />
                              ) : (
                                <FaXmark />
                              ))}
                            {(object.type === "string" ||
                              object.type === "number") &&
                              (row[object.attribute]
                                ? row[object.attribute]
                                : "none")}
                            {object.type == "currency" &&
                              (row[object.attribute]
                                ? "€ " + row[object.attribute]
                                : "none")}
                            {object.type == "link" &&
                              (row[object.attribute] ? (
                                <a
                                  href={row[object.attribute]}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FaFilePdf className="fontAwesomeIcon" />
                                </a>
                              ) : (
                                "none"
                              ))}
                            {object.type === "avatar" &&
                              (row[object.attribute] ? (
                                <img
                                  src={
                                    (process.env.REACT_APP_CDN_URL ??
                                      window["REACT_APP_CDN_URL"] ??
                                      "https://cdn.amaseyewear.com") +
                                    "/" +
                                    row[object.attribute]
                                  }
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                  }}
                                />
                              ) : (
                                <img
                                  src="/avatar-placeholder.svg"
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                  }}
                                />
                              ))}
                            {object.type == "files_array" &&
                              renderFiles(row, object)}
                          </TableCell>
                        );
                      }
                    })}
                    {!params.hideActionButton && (
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate(row);
                          }}
                        >
                          {currentUserPermissions?.includes(
                            `edit-${params.model}`
                          ) ? (
                            <FaPen fontSize={18} />
                          ) : (
                            <FaEye color="primary" fontSize={18} />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {params.totalCount === 0 && (
            <NoResourceAvailable
              resource={params.model + "s"}
              image={params.image}
              addButtonLine={true}
            />
          )}
        </TableContainer>
        {params.totalCount > 0 && (
          <>
            <TablePagination
              className="pagination"
              component="div"
              page={params.page}
              rowsPerPage={params.rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 30]}
              count={params.totalCount}
              onPageChange={params.onPageChange}
              onRowsPerPageChange={params.onRowsChange}
            />
          </>
        )}
      </>
    </Box>
  );
};
export default GenericTable;
