import { useCallback, useMemo } from "react";
import { store } from "../store";
import { showErrorAlert } from "../store/actions/alerts";
import useUserData from "./useUserData";

export type TRoles = "superAdmin" | "admin";

export interface IRole {
  label: string;
  value: TRoles;
  permissions: string[];
}

export interface IRoleMapping {
  [key: string]: IRole;
}

export const defaultPermissions = (): string[] => {
  return [
    "add-product",
    "edit-product",
    "delete-product",
    "add-category",
    "edit-category",
    "delete-category",
    "add-order",
    // "edit-order",
    "delete-order",
    "add-customer",
    "edit-customer",
    "delete-customer",
    "add-brand",
    "edit-brand",
    "delete-brand",
  ];
};

/** Returns an array of role objects containing the role metadata and the permissions */
export const useRoles = (): IRole[] => {
  let commonPermissions = defaultPermissions();

  const roles: IRole[] = [
    {
      label: "Super Admin",
      value: "superAdmin",
      permissions: [
        "add-admin",
        "edit-admin",
        "delete-admin",
        ...commonPermissions,
      ],
    },
    {
      label: "Admin",
      value: "admin",
      permissions: [...commonPermissions],
    },
  ];

  return roles;
};

/** Fetches a mapping object of all the roles with the keys being role values */
export const useRolesMapping = (): IRoleMapping => {
  const roles = useRoles();

  const roleMapping: IRoleMapping = {};

  roles.forEach((role) => (roleMapping[role.value] = role));

  return roleMapping;
};

/** Fetch the permissions for a specific role */
export const useRolePermissions = (role: string): string[] => {
  const rolesMapping = useRolesMapping();

  return useMemo(
    () => (rolesMapping[role] ? rolesMapping[role].permissions : []),
    [role]
  );
};

/** Fetch active user permissions */
export const useActiveUserPermissions = (): string[] => {
  const result = useUserData();

  // return useRolePermissions(result?.role ?? "");
  return useRolePermissions((result?.super ? "superAdmin" : "admin") ?? "");
};

/** Returns a method that helps you check to see if a user has a specific permission */
export const usePermissionChecker = () => {
  const permissions = useActiveUserPermissions();

  // stringified the arrays, in order to prevent react's triggers for new arrays with the same values
  const permissionsStringified = JSON.stringify(permissions);

  return useCallback(
    (role: string, showAlert: boolean = false): boolean => {
      const hasPermission: boolean = !!permissions.includes(role);

      if (!hasPermission && showAlert)
        store.dispatch(
          showErrorAlert({
            message: `You don't have the ${role} permission.`,
          })
        );

      return hasPermission;
    },
    [permissionsStringified]
  );
};
