import { useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import useScreenSize from "./hooks/useScreenSize";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import theme from "./services/theme";

import ErrorAlert from "./components/Alerts/ErrorAlert/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert/SuccessAlert";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import ProtectedRoute from "./components/RouteComponents/ProtectedRoute";
import SessionRoute from "./components/RouteComponents/SessionRoute";
import Sidebar from "./components/Sidebar/Sidebar";
import OrdersTableView from "pages/Orders/OrdersTableView/OrdersTableView";
import AddEditOrder from "pages/Orders/AddEditOrder/AddEditOrder";
import AdminsTableView from "pages/Admins/AdminsTableView/AdminsTableView";
import AddEditAdmin from "pages/Admins/AddEditAdmin/AddEditAdmin";
import AddEditCustomer from "pages/Customers/AddEditCustomer/AddEditCustomer";
import CustomersTableView from "pages/Customers/CustomersTableView/CustomersTableView";
import ProductsTableView from "pages/Products/ProductsTableView/ProductsTableView";
import AddEditProduct from "pages/Products/AddEditProduct/AddEditProduct";
import BrandsTableView from "pages/Brands/BrandsTableView/BrandsTableView";
import AddEditBrand from "pages/Brands/AddEditBrand/AddEditBrand";
import CategoryTableView from "pages/Categories/CategoryTableView/CategoryTableView";
import AddEditCategory from "pages/Categories/AddEditCategory/AddEditCategory";
import Forgot from "./pages/forgot/Forgot";
import Login from "./pages/login/Login";
import Reset from "./pages/reset/Reset";

import Box from "@mui/material/Box";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";

import "./App.scss";
import useUserData from "hooks/useUserData";

declare global {
  interface Window {
    REACT_APP_API_URL: string;
    REACT_APP_PORTAL_URL: string;
  }
}

const App = () => {
  const device = useScreenSize();
  // @ts-ignore
  const mainTheme = useMemo(() => createTheme(theme()), []);

  const session = useStoreSessionSelector();

  const user = useUserData();

  return (
    <>
      <Box>
        <ThemeProvider theme={mainTheme}>
          <ErrorAlert />
          <SuccessAlert />
          <BrowserRouter>
            {session.token && (
              <>
                {device !== "mobile" ? (
                  <div className="p-0" style={{ float: "left" }}>
                    {
                      <Box className="sideBox">
                        <Sidebar />
                      </Box>
                    }
                  </div>
                ) : (
                  <MobileMenu />
                )}
              </>
            )}

            <Box className="contentBox">
              <Switch>
                <Redirect exact from="/" to="/orders" />

                <SessionRoute
                  exact
                  path="/login"
                  token={session.token}
                  component={Login}
                />

                <SessionRoute
                  exact
                  path="/forgot"
                  token={session.token}
                  component={Forgot}
                />

                <SessionRoute
                  exact
                  path="/reset"
                  token={session.token}
                  component={Reset}
                />

                <ProtectedRoute
                  exact
                  path="/orders"
                  token={session.token}
                  component={OrdersTableView}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/orders/add"
                  token={session.token}
                  component={AddEditOrder}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/orders/:id"
                  token={session.token}
                  component={AddEditOrder}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/brands"
                  token={session.token}
                  component={BrandsTableView}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/brands/add"
                  token={session.token}
                  component={AddEditBrand}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/brands/:id"
                  token={session.token}
                  component={AddEditBrand}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/categories"
                  token={session.token}
                  component={CategoryTableView}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/categories/add"
                  token={session.token}
                  component={AddEditCategory}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/categories/:id"
                  token={session.token}
                  component={AddEditCategory}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/products"
                  token={session.token}
                  component={ProductsTableView}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/products/add"
                  token={session.token}
                  component={AddEditProduct}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/products/:id"
                  token={session.token}
                  component={AddEditProduct}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/admins"
                  token={session.token}
                  component={AdminsTableView}
                  user={user}
                  allowedRoles={["superAdmin"]}
                />

                <ProtectedRoute
                  exact
                  path="/admins/add"
                  token={session.token}
                  component={AddEditAdmin}
                  user={user}
                  allowedRoles={["superAdmin"]}
                />

                <ProtectedRoute
                  exact
                  path="/admins/:id"
                  token={session.token}
                  component={AddEditAdmin}
                  user={user}
                  allowedRoles={["superAdmin"]}
                />

                <ProtectedRoute
                  exact
                  path="/customers"
                  token={session.token}
                  component={CustomersTableView}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/customers/add"
                  token={session.token}
                  component={AddEditCustomer}
                  user={user}
                />

                <ProtectedRoute
                  exact
                  path="/customers/:id"
                  token={session.token}
                  component={AddEditCustomer}
                  user={user}
                />

                {/* Whenever the path doesn't match with any route, redirect to home */}
                <Route>
                  <Redirect to={"/"} />
                </Route>
              </Switch>
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default App;
