import { useEffect, useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import ProductService from "services/Entities/ProductService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditProductForm from "../components/AddEditProductForm";

import "./AddEditProduct.scss";
import ConfigService from "services/Entities/ConfigService";

const AddEditProduct = () => {
  const currentuserPermissions = useActiveUserPermissions();

  const [formState, setFormState] = useState("create");
  const [object, setObject] = useState({
    id: "",
    modelName: "",
    sku: "",
    brandName: "",
    releaseDate: "",
    weight: 0,
    weightUnit: "",
    cost: 0,
    price: 0,
    bestSeller: false,
    specSegment: "",
    specType: "",
    variants: [
      {
        id: "",
        variantName: "Variant 1",
        // lensDescription: "",
        modelCode: "",
        colorCode: "",
        colorCss: "rgb(151,2,4)",
        colorFamily: "",
        stockValue: 0,
        specifications: {
          lensHeight: 0,
          lensWidth: 0,
          lensDiagonal: 0,
          lensMaterial: "",
          bridgeNodeLengthSize: 0,
          templeLengthSize: 0,
        },
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [markupRate, setMarkupRate] = useState(0);
  const [colorNames, setColorNames] = useState<any>(null);

  const productsClient = new ProductService();
  const configsClient = new ConfigService();

  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const fetchMarkup = () => {
    const payload = {};

    configsClient.getAllResources(payload).then((response) => {
      return setMarkupRate(response?.data?.data?.markup);
    });
  };

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-product", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no product to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      productsClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          }
          history.push("/products");
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = (tempColors?: any) => {
    productsClient.getSpecificResource(id, {}).then((res) => {
      setFormState("edit");

      const tempObj = { ...res?.data?.data };

      if (tempColors) {
        tempObj?.variants?.map((variant: any, index: number) => {
          tempObj.variants[index].fullColorName = tempColors?.find(
            (obj) => obj.key === variant.colorCode
          );

          const { key, value } = tempObj?.variants[index]?.fullColorName ?? {
            key: "",
            value: "",
          };
          tempObj.variants[index].fullColorName = `${key} - ${value}`;
        });
      }

      setObject(tempObj);
    });
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    for (let i = 0; i < object.variants.length; i++) {
      let variantName =
        object.variants[i].variantName !== ""
          ? object.variants[i].variantName
          : i + 1;

      if (!object.variants[i].id) {
        dispatch(
          showErrorAlert({ message: `Variant "${variantName}" ID is required` })
        );
        return;
      }

      if (object.variants[i].variantName === "") {
        dispatch(
          showErrorAlert({
            message: `Variant "${variantName}" Name is required`,
          })
        );
        return;
      }

      if (object.variants[i].modelCode === "") {
        dispatch(
          showErrorAlert({
            message: `Model Code for Variant "${variantName}" is required`,
          })
        );
        return;
      }

      if (isNaN(object.variants[i].stockValue)) {
        dispatch(
          showErrorAlert({
            message: `Stock Value for Variant "${variantName}" must be a number`,
          })
        );
        return;
      }

      if (isNaN(object.variants[i].specifications.lensHeight)) {
        dispatch(
          showErrorAlert({
            message: `Lens Height for Variant "${variantName}" must be a number`,
          })
        );
        return;
      }

      if (isNaN(object.variants[i].specifications.lensDiagonal)) {
        dispatch(
          showErrorAlert({
            message: `Lens Diagonal for Variant "${variantName}" must be a number`,
          })
        );
        return;
      }

      if (object.variants[i].specifications.lensMaterial === "") {
        dispatch(
          showErrorAlert({
            message: `Lens Material for Variant "${variantName}" is required`,
          })
        );
        return;
      }

      if (isNaN(object.variants[i].specifications.bridgeNodeLengthSize)) {
        dispatch(
          showErrorAlert({
            message: `Bridge Length for Variant "${variantName}" must be a number`,
          })
        );
        return;
      }

      if (isNaN(object.variants[i].specifications.templeLengthSize)) {
        dispatch(
          showErrorAlert({
            message: `Temple Length for Variant "${variantName}" must be a number`,
          })
        );
        return;
      }
    }

    if (formState === "create") {
      if (!permissionChecker("add-product", true)) return;

      setLoading(true);

      const payload = {
        ...object,
        releaseDate: object?.releaseDate.endsWith("00Z")
          ? object?.releaseDate
          : object?.releaseDate + ":00Z",
      };

      productsClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          window.location.href = "/products";
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-product", true)) return;

      setLoading(true);

      const payload = {
        ...object,
        releaseDate: object?.releaseDate.endsWith("00Z")
          ? object?.releaseDate
          : object?.releaseDate + ":00Z",
      };

      productsClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const [markupResponse, colorResponse] = await Promise.all([
          fetchMarkup(),
          configsClient.getColorNames(),
        ]);

        const tempColors = Object.keys(colorResponse?.data?.data).map(
          (key) => ({
            key,
            value: colorResponse?.data?.data[key],
          })
        );

        setColorNames(tempColors);

        if (id) {
          fetchSpecificResource(tempColors);
        }
      } catch (error) {
        fetchSpecificResource();
      }
    };

    fetchData();

    setLoading(false);
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        selectedRows={[{ id }]}
        model="product"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h3"
              sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
            >
              {formState === "create" &&
                currentuserPermissions?.includes("add-product") &&
                "Add "}
              {formState !== "create" &&
                currentuserPermissions?.includes("edit-product") &&
                "Edit "}
              {formState !== "create" &&
                !currentuserPermissions?.includes("edit-product") &&
                "View "}
              Product
            </Typography>
            {object?.sku && (
              <Button
                variant="text"
                href={`https://www.youandsafilo.com/ccrz__ProductDetails?role=S2&sku=${object?.sku}`}
                target="_blank"
              >
                View on You & Safilo
              </Button>
            )}
          </Box>

          <AddEditProductForm
            object={object}
            setObject={setObject}
            formState={formState}
            markupRate={markupRate}
            colorNames={colorNames}
            submitResource={submitResource}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditProduct;
