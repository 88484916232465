import { useActiveUserPermissions } from "hooks/useRoles";

import ImageDropzone from "components/ImageDropzone/ImageDropzone";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import DisplayImages from "components/DisplayImages/DisplayImages";

const AddEditBrandForm = ({
  object,
  setObject,
  submitResource,
  formState,
  loading,
}: any) => {
  const currentuserPermissions = useActiveUserPermissions();

  const handleChange = (input: any) => {
    let tempObj: any = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  const removeImage = (index: number) => {
    let tempObj: any = object;
    tempObj["imageLink"] = "";
    setObject({ ...tempObj });
  };

  return (
    <form onSubmit={submitResource}>
      <TextField
        required
        fullWidth
        label="Code"
        name="code"
        value={object ? object.code : ""}
        onChange={(e) => handleChange(e.target)}
        className="my-2"
      />
      <TextField
        required
        fullWidth
        label="Name"
        name="name"
        value={object ? object.name : ""}
        onChange={(e) => handleChange(e.target)}
        className="mt-2"
      />

      <Box mt={3}></Box>

      {object?.imageLink ? (
        <DisplayImages
          imageLinks={[object.imageLink]}
          removeImage={removeImage}
          brand={true}
        />
      ) : null}

      <Box mt={5}>
        <ImageDropzone
          multiple={false}
          entity={"brand"}
          object={object}
          setObject={setObject}
        />
      </Box>

      {currentuserPermissions?.includes("edit-brand") && (
        <Button
          variant="contained"
          sx={{ color: "white", mt: "30px", width: "100%" }}
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="1rem" />
          ) : formState === "create" ? (
            "Add Brand"
          ) : (
            "Update Brand"
          )}
        </Button>
      )}
    </form>
  );
};

export default AddEditBrandForm;
