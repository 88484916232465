import { TableConfig } from "interfaces/tableConfig";

const orders: TableConfig[] = [
  {
    heading: "ID",
    attribute: "id",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Total",
    attribute: "total",
    type: "currency",
    sortable: true,
    selected: true,
  },
  {
    heading: "Status",
    attribute: "status",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Created At",
    attribute: "createdAt",
    type: "date",
    sortable: true,
    selected: true,
  },
];

export default orders;
