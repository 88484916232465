import ApiRequests from "../apiRequests";

class ProductService extends ApiRequests {
  constructor() {
    super("products");
  }

  async validateCSV(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/import/products/validate`,
      payload,
      "POST",
      {
        signal: abortController.signal,
      }
    );
  }

  async importProducts(
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/import/products/save`,
      payload,
      "POST",
      {
        signal: abortController.signal,
      }
    );
  }
}

export default ProductService;
