import axios from "axios";

const baseUrl =
  process.env.REACT_APP_API_URL ??
  window["REACT_APP_API_URL"] ??
  "https://api.amaseyewear.com/api/v1";

export const axiosFactory = () => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((req) => {
    if (localStorage["ACCESS_TOKEN"]) {
      req.headers!["Authorization"] = `${localStorage["ACCESS_TOKEN"]}`;
    }
    return req;
  });

  return axiosInstance;
};

export default axiosFactory;
