import { apiContext } from "./context";
import { useState, useMemo, useEffect, useCallback, useContext } from "react";

import AdminService from "services/Entities/AdminService";
import AuthenticationService from "services/Entities/AuthenticationService";
import BrandService from "services/Entities/BrandService";
import CategoryService from "services/Entities/CategoryService";
import ConfigService from "services/Entities/ConfigService";
import CustomerService from "services/Entities/CustomerService";
import ImageService from "services/Entities/ImageService";
import OrderService from "services/Entities/OrderService";
import ProductService from "services/Entities/ProductService";

type ApiRequestCallback<Data = any, Payload = any> =
  | ((payload: Payload, ab?: AbortController) => Promise<Data>)
  | ((id: string, payload: Payload, ab?: AbortController) => Promise<Data>);

type RequestOf<O extends object> = {
  [Key in keyof O]: O[Key] extends ApiRequestCallback ? Key : never;
}[keyof O];

type ArgumentsOf<C> = C extends (...args: infer Args) => Promise<any>
  ? Args
  : never;
type ReturnOf<C> = C extends (...args: any[]) => Promise<infer R> ? R : never;

const services = {
  Admins: new AdminService(),
  Customers: new CustomerService(),
  Authentication: new AuthenticationService(),
  Products: new ProductService(),
  Brands: new BrandService(),
  Orders: new OrderService(),
  Categories: new CategoryService(),
  Configs: new ConfigService(),
  Images: new ImageService(),
} as const;

type Services = typeof services;

export default function useApiHook<
  S extends keyof Services,
  C extends RequestOf<Services[S]>,
  A extends ArgumentsOf<Services[S][C]>,
  D extends ReturnOf<Services[S][C]>
>(service: S, callback: C, ...args: A) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<D>();
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const refresh = () => setRefreshTrigger(!refreshTrigger);

  const argsStringified = useMemo(() => JSON.stringify(args), [args]);

  useEffect(() => {
    if (typeof services[service][callback] !== "function") {
      return;
    }

    setLoading(true);
    let ab = new AbortController();
    const _args = [...args];
    if (_args[_args.length - 1] instanceof AbortController) {
      ab = _args[_args.length - 1] as AbortController;
    } else {
      _args[_args.length] = new AbortController();
    }

    // @ts-ignore
    (services[service][callback] as ApiRequestCallback)(...(_args as any))
      .then((r) => {
        setData(r.data);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      ab.abort();
    };
  }, [service, callback, argsStringified, refreshTrigger]);

  return {
    loading,
    data,
    refresh,
    setData,
  };
}

export function useApiHookCallback<
  S extends keyof Services,
  C extends RequestOf<Services[S]>,
  A extends ArgumentsOf<Services[S][C]>,
  D extends ReturnOf<Services[S][C]>
>(service: S, callback: C) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<D>();
  const { onModelsUpdate } = useContext(apiContext);

  const Service = useMemo(() => {
    return services[service];
  }, [service]);

  const request = useCallback(
    async (...args: A) => {
      setLoading(true);

      // @ts-ignore
      return (Service[callback] as NonNullable<ApiRequestCallback>)(
        // @ts-ignore
        ...(args as any)
      )
        .then(async (r) => {
          let action: any;
          let models: any[] = [];

          switch (callback) {
            // case 'post'
            case "postResource":
              action = "create";
              models = [
                {
                  ...r.data?.data,
                  ...(args[0] as any),
                },
              ];

              break;
            case "patchResource":
              action = "update";
              models = [
                {
                  ...r.data?.data,
                  ...(args[0] as any),
                },
              ];
              break;
            case "deleteResource":
              action = "delete";
              break;
            case "deleteManyResource":
              models = ((args[0] as any)?.data || []).map((_id: any) => ({
                _id,
              }));
              action = "delete";
              break;
          }

          await onModelsUpdate({
            action,
            modelKey: service,
            models,
          });
          return r;
        })
        .finally(() => {
          setLoading(false);
          console.log("finally");
        });
    },
    [service, callback, onModelsUpdate]
  );

  return {
    loading,
    request,
    data,
    setData,
  };
}
