import { useState } from "react";
import { useHistory } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";

import products from "configs/products";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";

import { useApiHook } from "providers/ApiProvider";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import placeholderPic from "../../../assets/no_data_x.gif";
import ConfigService from "services/Entities/ConfigService";
import ProductService from "services/Entities/ProductService";
import { showErrorAlert, showSuccessAlert } from "store/actions/alerts";
import ImportProducts from "../components/ImportProducts";

const ProductsTableView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const configsClient = new ConfigService();

  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [markupRate, setMarkupRate] = useState("");
  const [openMarkupDialog, setOpenMarkupDialog] = useState(false);
  const [importProductsModal, setImportProductsModal] =
    useState<boolean>(false);

  const [queryParams, setQueryParams] = useUrlState({
    // q: undefined,
    page: 0,
    size: 10,
    sortBy: "releaseDate",
    sortDirection: "desc",
  });

  // const [searchQuery, setSearchQuery] = useState(
  //   localStorage.getItem("searchQuery") || ""
  // );

  // const permissionChecker = usePermissionChecker();

  const query = {
    // q: searchQuery ?? "",
    // _page: queryParams.page,
    // _limit: queryParams.size,
    // _sort: queryParams.sortBy ?? "created_at",
    // _order: queryParams.sortDirection ?? "desc",
    pageQuery: {
      page: Number(queryParams.page) + 1,
      size: Number(queryParams.size),
    },
    orderBy: {
      field: queryParams.sortBy,
      order: queryParams.sortDirection,
    },
  };

  const productData = useApiHook(
    "Products",
    "postSpecificResource",
    "query",
    query
  );

  const closeMarkupDialog = () => {
    setOpenMarkupDialog(false);
  };

  // const fetchMarkup = () => {
  //   setLoading(true);

  //   const payload = {};

  //   configsClient
  //     .getAllResources(payload)
  //     .then((response) => {
  //       return setMarkupRate(response?.data?.data?.markup);
  //     })
  //     .finally(() => setLoading(false));
  // };

  // const updateMarkup = () => {
  //   setLoading(true);

  //   const payload = {
  //     markup: markupRate,
  //   };

  //   configsClient
  //     .postResource(payload)
  //     .then((response) => {
  //       if (response?.data?.status === "success") {
  //         dispatch(showSuccessAlert(response?.data?.message));
  //       } else {
  //         dispatch(showErrorAlert(response?.data?.message));
  //       }
  //       setOpenMarkupDialog(false);
  //     })
  //     .finally(() => setLoading(false));
  // };

  // const handleSearch = (value: string) => {
  //   localStorage.setItem("searchQuery", value);
  //   setSearchQuery(value);

  //   if (queryParams.q !== value) {
  //     setQueryParams({ q: value, page: 0 });
  //   } else {
  //     setQueryParams({ q: value });
  //   }
  // };

  // const deleteProduct = () => {
  //   if (!permissionChecker("delete-product", true)) return;

  //   productsClient
  //     .deleteManyResource({ data: selectedRows })
  //     .then(() => fetchData());
  // };

  const navigateToAddPage = () => {
    history.push(`products/add`);
  };

  const rowTriggerAction = (row: any) => {
    history.push(`products/${row?.id}`);
  };

  const handlePageChange = (e: any, value: any) => {
    setQueryParams({ page: Number(value) });
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setQueryParams({ page: 0, size: parseInt(e.target.value) });
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  const onColumnSort = (c: any) => {
    if (queryParams.sortBy === c) {
      setQueryParams((s) => ({
        sortDirection: s.sortDirection === "asc" ? "desc" : "asc",
      }));
    } else {
      setQueryParams({ sortBy: c, sortDirection: "asc" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        // onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        // deleteResourceHandler={deleteProduct}
        customButtonsLabels={["Import"]}
        customButtonsHandlers={[
          () => {
            setImportProductsModal(true);
          },
        ]}
        selectedRows={selectedRows}
        model="product"
      />
      <div className="p-4">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="mb-4">Products</h2>
          {/* <Button
            variant="contained"
            onClick={() => {
              setOpenMarkupDialog(true);
            }}
          >
            Set Markup
          </Button> */}
        </Box>
        <GenericTable
          showCheckbox={false}
          list={productData?.data?.data ? productData?.data?.data : []}
          totalCount={productData?.data?.meta?.pagination?.total || 0}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={Number(queryParams.page)}
          loading={productData.loading}
          rowsPerPage={Number(queryParams.size)}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={products}
          model="product"
          passRow={rowTriggerAction}
          columnSort={queryParams.sortBy}
          columnSortDir={queryParams.sortDirection}
          onColumnSort={onColumnSort}
        />
      </div>

      {/* <Dialog open={openMarkupDialog} onClose={closeMarkupDialog}>
        <DialogTitle>Set Markup</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={markupRate}
            margin="dense"
            id="markup"
            label="Markup Rate"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setMarkupRate(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMarkupDialog}>Cancel</Button>
          <Button onClick={updateMarkup}>Update</Button>
        </DialogActions>
      </Dialog> */}

      <ImportProducts
        importProductsModal={importProductsModal}
        setImportProductsModal={setImportProductsModal}
        productData={productData}
      />
    </Box>
  );
};

export default ProductsTableView;
