import { AxiosResponse } from "axios";
import React from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../store/actions/session";
import "./Reset.scss";
import ResetPasswordForm from "../../components/PageComponents/Authentication/resetPasswordForm";
import { showSuccessAlert } from "../../store/actions/alerts";

const Reset: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [password, setPassword] = React.useState<string>("");
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  const dispatch = useDispatch();
  const history = useHistory();

  const reset = () => {
    const payload = {
      email: email,
      token: token,
      password: password,
    };

    dispatch(resetPassword(payload, history));
  };

  return (
    <>
      <ResetPasswordForm
        reset={reset}
        setPassword={setPassword}
        email={email}
      />
    </>
  );
};

export default Reset;
