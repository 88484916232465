import { Fragment } from "react";

import { useRoles } from "../../../hooks/useRoles";

const AdminRoles = ({ adminRole, setAdminRole }: any) => {
  const roles = useRoles();

  return (
    <>
      <div
        style={{
          columnGap: "10px",
          rowGap: "5px",
          display: "flex",
          flexWrap: "wrap",
          width: "fit-content",
        }}
      >
        {roles.map((role, index) => (
          <Fragment key={role.value}>
            <div
              className={
                (adminRole === role.value ? "roleSelected" : "roleUnselected") +
                " text-center"
              }
              onClick={() => setAdminRole(role.value)}
            >
              {role.label}
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default AdminRoles;
