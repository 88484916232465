import getVariantBackground from "lib/getVariantBackground";
import { template } from "lodash";
import { applyInlineStyling } from "./style";
import { ORDER_ITEM_TEMPLATE, ORDER_TEMPLATE } from "./templates";

const s3BaseUrl =
  process.env.REACT_APP_CDN_URL ??
  window["REACT_APP_CDN_URL"] ??
  "https://staging-cdn.amaseyewear.com";

const templateConfig = {
  interpolate: /{{([\s\S]+?)}}/g,
};

const messages: Record<any, string> = {
  pending:
    "We have received your order and it will be shipped to you as soon as possible.",
  cancelled:
    "Your order has been cancelled, please contact support for more information",
  complete: "Your order has been delivered",
  confirmed: "Your order has been confirmed and will ship shortly",
};

const orderTemplate = template(ORDER_TEMPLATE, templateConfig);
const orderItemTemplate = template(ORDER_ITEM_TEMPLATE, templateConfig);

export function parseS3ImageLink(link: string) {
  return `${s3BaseUrl}/${link}`;
}

export function getOrderItemTemplate(
  product: any,
  quantity: number,
  colorNames?: any
) {
  return orderItemTemplate({
    product,
    IMAGE_LINK: parseS3ImageLink(product.variants[0]?.imageLinks[0] ?? ""),
    quantity,
    colorNames,
    getVariantBackground,
  });
}

export function getOrderTemplate({
  order,
  customer,
  colorNames,
}: {
  customer: any;
  order: any;
  colorNames: any;
}) {
  return applyInlineStyling(
    orderTemplate({
      customer,
      order,
      message: messages[order.status],
      ORDER_ITEMS: order.items
        .map((i) => {
          return getOrderItemTemplate(i.product, i.quantity, colorNames);
        })
        .join("\n"),
    })
  );
}
