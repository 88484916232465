import ImageDropzone from "components/ImageDropzone/ImageDropzone";
import DisplayImages from "components/DisplayImages/DisplayImages";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const VariantImages = ({
  object,
  setObject,
  imageLinks,
  index,
  removeImage,
}: any) => {
  return (
    <>
      <Typography
        variant="h4"
        color="secondary"
        gutterBottom
        sx={{ textTransform: "uppercase" }}
      >
        Images
      </Typography>

      {imageLinks && imageLinks.length < 1 && (
        <Typography variant="h5">No Image exists</Typography>
      )}

      <DisplayImages
        imageLinks={imageLinks}
        variantIndex={index}
        removeImage={removeImage}
      />

      <Box sx={{ mb: 5 }}></Box>

      <ImageDropzone
        entity={"products"}
        variantIndex={index}
        object={object}
        setObject={setObject}
      />
    </>
  );
};

export default VariantImages;
