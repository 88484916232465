import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import useScreenSize from "hooks/useScreenSize";
import { forgotPassword } from "../../store/actions/session";

import LoginCoverBackground from "../../components/PageComponents/Authentication/LoginCoverBackground";
import ForgotForm from "../../components/PageComponents/Authentication/ForgotForm";

import "./Forgot.scss";

const Forgot = () => {
  const [email, setEmail] = useState<string>("");

  const dispatch = useDispatch();
  const history = useHistory();
  const device = useScreenSize();

  const forgot = () => {
    const payload = {
      email: email,
    };

    dispatch(forgotPassword(payload, history));
  };

  return (
    <div className="container-fluid p-0" style={{ height: "100vh" }}>
      <div className="row h-100">
        {device === "mobile" ? null : <LoginCoverBackground />}

        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-container"
          style={{ padding: "100px" }}
        >
          <h3>Reset password</h3>
          <ForgotForm setEmail={setEmail} forgot={forgot} email={email} />
        </div>
      </div>
    </div>
  );
};

export default Forgot;
