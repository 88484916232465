import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginMobile from "../../components/PageComponents/Authentication/LoginMobile";
import LoginPC from "../../components/PageComponents/Authentication/LoginPC";
import useScreenSize from "../../hooks/useScreenSize";
import { loginUser } from "../../store/actions/session";
import "./Login.scss";
import { apiContext } from "providers/ApiProvider/context";

const Login: React.FC = () => {
  const { onModelsUpdate } = useContext(apiContext);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [imgUrl, setImgUrl] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const device = useScreenSize();
  const dispatch = useDispatch();
  const history = useHistory();

  const getImgUrl = (img: string) => setImgUrl(img);
  const detectErr = (e: boolean) => (e ? setOpen(false) : setOpen(true));

  const login = () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };
    dispatch(
      loginUser(payload, setLoading, history, getImgUrl, detectErr, () => {
        onModelsUpdate({
          modelKey: "Authentication",
          action: "update",
          models: [
            {
              _id: "me",
            },
          ],
        });
      })
    );
  };

  const handleRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      localStorage.setItem("REFRESH_AUTH", JSON.stringify(true));
    } else {
      if (localStorage.getItem("REFRESH_AUTH")) {
        localStorage.removeItem("REFRESH_AUTH");
      }
    }
  };

  return (
    <>
      {device !== "mobile" ? (
        <LoginPC
          setEmail={setEmail}
          setPassword={setPassword}
          login={login}
          loading={loading}
          handleRememberMe={handleRememberMe}
        />
      ) : (
        <LoginMobile
          setEmail={setEmail}
          setPassword={setPassword}
          login={login}
          loading={loading}
          handleRememberMe={handleRememberMe}
        />
      )}
    </>
  );
};

export default Login;
