import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import {
  FaCartShopping,
  FaRibbon,
  FaUserShield,
  FaGlasses,
  FaUsers,
  FaFolderTree,
  FaRightFromBracket,
} from "react-icons/fa6";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

import avantLogo from "../../assets/avant-logo-3-white_SVG.svg";
import userPlaceholderImage from "../../assets/user-placeholder.png";
import useScreenSize from "../../hooks/useScreenSize";
import useUserData from "hooks/useUserData";
import { logout } from "../../store/actions/session";

import "./Sidebar.scss";

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useUserData();

  const [collapsed, setCollapsed] = useState<boolean>(
    localStorage.getItem("collapsed") === "true" || false
  );
  const [activeRoute, setActiveRoute] = useState<string>(
    window.location.pathname.split("/")[1]
  );

  const [routes, setRoutes] = useState<any>([]);

  const regularRoutes = [
    {
      path: "/orders",
      label: "Orders",
      routeActiveHandler: "orders",
      icon: FaCartShopping,
    },
    {
      path: "/brands",
      label: "Brands",
      routeActiveHandler: "brands",
      icon: FaRibbon,
    },
    {
      path: "/categories",
      label: "Categories",
      routeActiveHandler: "categories",
      icon: FaFolderTree,
    },
    {
      path: "/products",
      label: "Products",
      routeActiveHandler: "products",
      icon: FaGlasses,
    },
    {
      path: "/customers",
      label: "Customers",
      routeActiveHandler: "customers",
      icon: FaUsers,
    },
  ];

  const adminRoutes = [
    ...regularRoutes,
    {
      path: "/admins",
      label: "Admins",
      routeActiveHandler: "admins",
      icon: FaUserShield,
    },
  ];

  useEffect(() => {
    user?.super ? setRoutes([...adminRoutes]) : setRoutes([...regularRoutes]);
  }, [user]);

  const device = useScreenSize();
  const { pathname } = useLocation();

  const logUserOut = (): void => {
    dispatch(logout());
  };

  return (
    <Box
      className={"sidebar " + (collapsed ? "" : "full")}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <img className="logo" src="/LogoLight.svg" />
      <Box
        className="sidebarAction"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            localStorage.setItem("collapsed", String(!collapsed));
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? (
            <FaAngleRight className="icon" />
          ) : (
            <FaAngleLeft className="icon" />
          )}
        </IconButton>
      </Box>
      <Box className="topBox">
        {device !== "tablet" && !collapsed && (
          <Box
            className="laptopBox"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="d-flex w-100 justify-content-center">
              <img
                src={userPlaceholderImage}
                alt="user photo"
                style={{ marginTop: "10px", width: "50px", height: "50px" }}
              />
            </div>
            <p className="mb-0 mt-4 text-center" style={{ fontSize: "14px" }}>
              {user?.email}
            </p>
            <p className="text-center roleDisplay">
              {user.super ? "Super Admin" : "Admin"}
            </p>
          </Box>
        )}
      </Box>

      <Box
        className="linksBox"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {routes.map((route, index) => (
          <Fragment key={route.path}>
            <Link
              to={{
                pathname: route.path,
                state: pathname,
              }}
              className={
                "sideLink " +
                (activeRoute === route.routeActiveHandler ? "active" : "")
              }
              onClick={() => setActiveRoute(route.routeActiveHandler)}
            >
              {route.icon ? <route.icon fontSize={18} /> : route.imgSrc}
              <span style={{ marginLeft: "35px" }}>{route.label}</span>
            </Link>
          </Fragment>
        ))}

        <div onClick={logUserOut}>
          <a className="sideLink">
            <FaRightFromBracket fontSize={18} />
            <span style={{ marginLeft: "35px" }}>Log out</span>
          </a>
        </div>
      </Box>
      {!collapsed && (
        <div className="d-flex justify-content-center">
          <img className="bottomLogo" src={avantLogo} />
        </div>
      )}
    </Box>
  );
};

export default Sidebar;
