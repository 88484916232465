export interface AmassState {
  session: {
    token: string;
  };
  user: {
    id: string;
    email: string;
    super: boolean;
  };
  alerts: {
    success: {
      message: string;
      open: boolean;
    };
    error: {
      message: string;
      open: boolean;
    };
  };
}

const initialState: AmassState = {
  session: {
    token: "",
  },
  user: {
    id: "",
    email: "",
    super: false,
  },
  alerts: {
    success: {
      message: "",
      open: false,
    },
    error: {
      message: "",
      open: false,
    },
  },
};

export default initialState;
