import { useRef, useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

import GenericModal from "components/Modal/Modal";

import { useDispatch } from "react-redux";
import ProductService from "services/Entities/ProductService";
import { showErrorAlert, showSuccessAlert } from "store/actions/alerts";

const ImportProducts = ({
  importProductsModal,
  setImportProductsModal,
  productData,
}: any) => {
  const productsClient = new ProductService();
  const dispatch = useDispatch();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const content = event.target.result;
        resolve(content);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const importProducts = async () => {
    const file = fileInputRef.current?.files?.[0];

    if (!file) {
      dispatch(showErrorAlert({ message: "Choose File First" }));
      return;
    }

    let fileContent;

    try {
      fileContent = await readFile(file);
    } catch (error) {
      console.error("Error reading file: ", error);
    }

    const payload = {
      data: fileContent,
    };

    // Validate CSV File
    productsClient
      .validateCSV(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          // Upload CSV File
          productsClient.importProducts(payload).then((response2) => {
            if (response2?.data?.status === "success") {
              dispatch(showSuccessAlert(response2?.data?.message));
              setImportProductsModal(false);
              productData.refresh();
            }
          });
        }
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage(err?.response?.data?.errors);
      })
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      });
  };

  useEffect(() => {
    setShowError(false);
  }, [importProductsModal]);

  return (
    <GenericModal
      size="small"
      onClose={() => setImportProductsModal(false)}
      state={importProductsModal}
    >
      <Box display="flex" flexDirection="column">
        <h4 className="mb-6" style={{ alignSelf: "center" }}>
          Import Products
        </h4>

        {showError && Array.isArray(errorMessage) && (
          <>
            <Alert severity="error" sx={{ mb: 4 }}>
              <AlertTitle>Fix these errors</AlertTitle>
              {errorMessage.map((err: any) => {
                return (
                  <>
                    <span>
                      <strong>Row: </strong> {err.row}{" "}
                    </span>
                    <br />
                    <span>
                      <strong>Column: </strong> {err.column}{" "}
                    </span>
                    <br />
                    <span>
                      <strong>Error: </strong> {err.error ?? err.message}{" "}
                    </span>
                    <br />
                    <br />
                  </>
                );
              })}
            </Alert>
          </>
        )}

        <Box display="flex" alignItems="center">
          <input type="file" accept=".csv" ref={fileInputRef} />
          <Button variant="contained" onClick={importProducts} type="button">
            Import
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default ImportProducts;
