import { TableConfig } from "interfaces/tableConfig";

const customers: TableConfig[] = [
  {
    heading: "ID",
    attribute: "id",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Full Name",
    attribute: "fullName",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Email",
    attribute: "email",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Phone",
    attribute: "phoneNumber",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Status",
    attribute: "status",
    type: "string",
    sortable: true,
    selected: true,
  },
];

export default customers;
