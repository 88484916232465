import { ApiState, OnResponseArguments } from "../types";

export default function onResponseHandler({
  modelKey,
  response,
  responseKey,
  acceptsCreate,
}: OnResponseArguments): ApiState {
  const models: any[] = [];
  if ("result" in response) {
    if (Array.isArray(response.result)) {
      models.push(...response.result);
    } else if (typeof response.result === "object") {
      models.push(response.result);
    }
  }

  if ("data" in response) {
    if (Array.isArray(response.data)) {
      models.push(...response.data);
    } else if (typeof response.data === "object") {
      // models.push(response.data);
    }
  }

  return {
    models: {
      [modelKey]: models.reduce((acc, m) => ({ ...acc, [m._id]: m }), {}),
    },
    responses: {
      [responseKey]: {
        lastFetched: new Date(),
        modified: false,
        response,
        modelKey,
        modelIds: models?.map((m) => m?._id),
        acceptsCreate,
      },
    },
  };
}
