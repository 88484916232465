import { AxiosError } from "axios";

import AdminService from "services/Entities/AdminService";
import PasswordResetService from "../../services/Entities/PasswordResetService";
import { showSuccessAlert } from "./alerts";
import { getUser, unsetUser } from "./user";

export const START_SESSION = "START_TRADE_API_SESSION";
export const END_SESSION = "END_TRADE_API_SESSION";

export const startSession = (token: any, history: any) => {
  return async (dispatch: any) => {
    if (!token) return;

    localStorage.setItem("ACCESS_TOKEN", token);

    const action = {
      type: START_SESSION,
      payload: token,
    };
    dispatch(action);
  };
};

export const endSession = () => {
  return (async (dispatch: any) => {
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
  }) as any;
};

export const logout = () => {
  return (async (dispatch: any) => {
    const adminApi = new AdminService();
    const responseData = await adminApi.signout();

    if (responseData?.data?.status === "success") {
      localStorage.removeItem("ACCESS_TOKEN");

      const action = {
        type: END_SESSION,
      };

      dispatch(action);
      dispatch(unsetUser());

      dispatch(showSuccessAlert("Logged out successfully"));
    }
  }) as any;
};

export const postActionLogin = async (token: string, history: any) => {
  if (token) {
    history.push("/");
  }
};

export const loginUser = (
  payload: any,
  setLoading: any,
  history: any,
  getImgUrl: any,
  detectErr: any,
  onSuccess?: any
) => {
  return async (dispatch: any) => {
    try {
      const adminApi = new AdminService();
      const responseData = await adminApi.postSpecificResource(
        "auth/signin",
        payload
      );
      if (responseData.data.data.token) {
        dispatch(startSession(responseData.data.data.token, history));
        dispatch(getUser(responseData.data.data.token));
      } else {
        detectErr(false);
      }
      setLoading(false);
    } catch (e: AxiosError | any) {
      setLoading(false);
      detectErr(true);
    }
  };
};

export const forgotPassword = (payload: any, history: any) => {
  return async (dispatch: any) => {
    try {
      const passwordResetApi = new PasswordResetService();
      const responseData = await passwordResetApi.postSpecificResource(
        "/forgot",
        payload
      );
      responseData.data.success &&
        dispatch(showSuccessAlert("Check your email to reset your password.."));
      history.push("/");
    } catch (e: AxiosError | any) {
      console.log("error session forgot Password");
    }
  };
};

export const resetPassword = (payload: any, history: any) => {
  return async (dispatch: any) => {
    try {
      const passwordResetApi = new PasswordResetService();
      const responseData = await passwordResetApi.postSpecificResource(
        "reset",
        payload
      );
      responseData.data.success &&
        dispatch(showSuccessAlert("Password changed successfully."));
      history.push("/");
    } catch (e: AxiosError | any) {
      console.log("error session reset Password");
    }
  };
};
