import { css } from "@emotion/react";
import { load } from "cheerio";

export const STYLES = css`

    .wrapper {
        background-color: #f8f8f8;
        padding: 5px 0;
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
    }

    * {
      line-height: 1.5;
    }

    a {
      color: #990202;
    }

    .container {
        max-width: 650px;
        margin: 0 auto;
        background-color: white;
        color: black;
        padding: 25px 25px;
        border-radius: 25px;
        box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.05);
        font-size: 12px;
    }

    .logo {
        width: 100px; height: auto
    }

    .order-number {
        color: #555; font-size: 14px
    }

    .order-table {
        margin: 40px 0;
        border-collapse: collapse;
        width: 100%;PP
        border-radius: 5px;
        overflow: hidden;
        border-top: 1px solid #ddd;
    }

    .order-table th {
        // border: 1px solid #ddd;
        padding: 10px;
        text-align: left;
        // background-color: #f0f0f0;
        color: #555;
        font-weight: 400;
    }

    .order-table td {
        // border: 1px solid #ddd; 
        padding: 15px 10px;
        font-size: 12px;
    }

    .order-table td img {
        width: 100px; height: 50px; 
        object-fit: contain;
        object-position: center; 
    }

    .order-table .variant {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        display: inline-block;
        vertical-align: baseline;
        background-position: center;
        background-size: 100%;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .order-table .conclusion :is(th, td) {
        border-top: 1px solid #ddd;
    }

    .order-table .conclusion td {
        font-weight: 600;
        font-size: 15px;
    }

    .smallPriceQuantity {
      font-size: 10px;
      color: #a0a0a0;
    }

`;

function parseCSS(cssString: string) {
  const result: { selector: string; styles: string }[] = [];

  // Remove comments from CSS string
  const cleanedCSS = cssString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, "");

  // Split by closing brace to get blocks of rules
  const blocks = cleanedCSS.split("}");

  // Process each block to get selector and styles
  blocks.forEach((block) => {
    const [selector, styles] = block.split("{");
    if (selector && styles) {
      result.push({
        selector: selector.trim(),
        styles: styles.trim(),
      });
    }
  });

  return result;
}

// For html email the inline styling is mandatory so this function is used to apply the styles to the html elements
export const applyInlineStyling = (html: string, email = true) => {
  let $ = load(html, { xmlMode: true });
  const filter = email ? emailInlineCSS : (v: string) => v;
  const css = parseCSS(STYLES.styles);
  for (const { selector, styles } of css) {
    const elements = $(selector).toArray();
    for (const element of elements) {
      const previousStyles = $(element).attr("style") || "";
      $(element).attr("style", filter(`${previousStyles};\n ${styles}`));
    }
  }
  return $.html({ decodeEntities: false }).trim();
};

function emailInlineCSS(inlineCSS: string) {
  // List of allowed CSS properties based on the JSON array
  const allowedProperties = [
    "print-color-adjust",
    "-webkit-print-color-adjust",
    "background",
    "background-color",
    "border",
    "border-bottom",
    "border-collapse",
    "border-color",
    "border-left",
    "border-right",
    "border-spacing",
    "border-style",
    "border-top",
    "border-width",
    "border-radius",
    "clear",
    "color",
    "direction",
    "display",
    "font",
    "font-family",
    "font-size",
    "font-style",
    "font-variant",
    "font-weight",
    "height",
    "letter-spacing",
    "line-height",
    "list-style",
    "list-style-image",
    "list-style-position",
    "list-style-type",
    "margin",
    "margin-bottom",
    "margin-left",
    "margin-right",
    "margin-top",
    "max-width",
    "min-width",
    "padding",
    "padding-bottom",
    "padding-left",
    "padding-right",
    "padding-top",
    "page-break-after",
    "page-break-before",
    "page-break-inside",
    "table-layout",
    "text-align",
    "text-decoration",
    "text-indent",
    "text-transform",
    "vertical-align",
    "white-space",
    "width",
    "word-spacing",
  ];

  // Split the inline CSS string by semicolons to get individual rules
  const rules = inlineCSS.split(";");

  // Filter and reconstruct the inline CSS string
  const filteredCSS = rules
    .map((rule) => {
      const [property, value] = rule.split(":").map((s) => s.trim());
      if (allowedProperties.includes(property)) {
        return `${property}: ${value}`;
      }
      return null;
    })
    .filter((rule) => rule !== null)
    .join("; ");

  return filteredCSS;
}
