import getS3ImageUrl from "./getS3ImageUrl";

export default function getVariantBackground(colorCss: string): string {
  const background = /url\(\"(.+)\"\)/.exec(colorCss)?.[1];

  if (!background) {
    return colorCss;
  } else {
    return `url(${getS3ImageUrl(background)})`;
  }
}
