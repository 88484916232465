import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { FaChevronLeft } from "react-icons/fa6";

import "./GoBackButton.scss";

const GoBackButton = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Button
      className="px-3 py-1"
      onClick={goBack}
      variant="contained"
      startIcon={<FaChevronLeft color="#fff" className="icon" />}
    >
      <Box sx={{ display: { md: "initial", xs: "none" } }}>Go Back</Box>
    </Button>
  );
};

export default GoBackButton;
