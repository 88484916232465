import { useState } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { FaEye, FaEyeSlash } from "react-icons/fa6";

import "./PasswordField.scss";

interface PasswordFieldsInterface {
  object: any;
  setObject: any;
  formState?: "create" | "edit" | "show";
}

const PasswordFields: React.FC<PasswordFieldsInterface> = ({
  object,
  setObject,
  formState,
}) => {
  const [showOriginalPassword, setShowOriginalPassword] =
    useState<boolean>(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState<boolean>(false);

  const handleChange = (input: any) => {
    let tempObj: any = { ...object };
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  return (
    <>
      <div className="my-3">
        <TextField
          required={formState === "create"}
          inputProps={{ minLength: 8 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  className="pointer"
                  onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                >
                  {showOriginalPassword ? (
                    <FaEyeSlash className="eyeIcons" />
                  ) : (
                    <FaEye className="eyeIcons" />
                  )}
                </span>
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Password"
          name="password"
          type={showOriginalPassword ? "text" : "password"}
          value={object ? object?.password : ""}
          onChange={(e) => handleChange(e.target)}
        />
      </div>
      <div className="my-3">
        <TextField
          required={formState === "create"}
          inputProps={{ minLength: 8 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  className="pointer"
                  onClick={() =>
                    setShowConfirmationPassword(!showConfirmationPassword)
                  }
                >
                  {showConfirmationPassword ? (
                    <FaEyeSlash className="eyeIcons" />
                  ) : (
                    <FaEye className="eyeIcons" />
                  )}
                </span>
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type={showConfirmationPassword ? "text" : "password"}
          value={object ? object?.confirmPassword : ""}
          onChange={(e) => handleChange(e.target)}
        />
      </div>
      {object?.password !== object?.confirmPassword && (
        <p className="text-danger mb-0">
          <i>Both passwords must be matching!</i>
        </p>
      )}
      {object?.password && object?.password.length < 8 && (
        <p className="text-danger mb-0">
          <i>Password should be atleast 8 characters long</i>
        </p>
      )}
    </>
  );
};

export default PasswordFields;
