import ApiRequests from "../apiRequests";

class ConfigService extends ApiRequests {
  constructor() {
    super("config");
  }

  async getColorNames(): Promise<any> {
    return await this.doRequest(`${this.baseUrl}/config/colorNames`, "GET");
  }
}

export default ConfigService;
