export const ORDER_ITEM_TEMPLATE = `
    <tr class="">
        <td>
          <img
              src="{{IMAGE_LINK}}"
              alt="picture of {{product?.modelName}} from {{product?.brandName}}"
          />
          </td>
        <!-- VARIANT color -->
        <td>
          {{product?.modelName}} 
          <br/> 
          <small>
          {{product?.variants[0]?.colorCode 
              ? product?.variants[0]?.colorCode + '/' + colorNames?.find((option) => option.key === product?.variants[0]?.colorCode)?.value || ""
              : product?.variants[0]?.variantName 
          }}
            <span
              class="variant"
              style="background: {{product.variants[0]?.colorCss}};"
              ></span> 
          </small>
        </td>
        <td>x{{quantity}}</td>
        <td>
          &euro;{{product?.price * quantity}}
          {{ quantity > 1 ? '<br /><small class="smallPriceQuantity">(' + product?.price + ' x ' + quantity + ')</small>' : '' }}
        </td>
        <td>
        &euro;{{product?.cost * quantity}}
        {{ quantity > 1 ? '<br /><small class="smallPriceQuantity">(' + product?.cost + ' x ' + quantity + ')</small>' : '' }}
        </td>
    </tr>

`;

export const ORDER_TEMPLATE = `

<div class="wrapper">
<div class="container">
    <img
      src="https://staging.amaseyewear.com/logo.png"
      alt="Logo"
      class="logo"
    />
    <h1>Updates regarding your order</h1>
    <p class="order-number">Your order number is #{{order.id}}</p>
    <p>
      {{message}}
    </p>
    <p>Here are the details of your order:</p>
    <table class="order-table">
      <thead>
        <tr>
          <th>Product</th>
          <th>Name</th>
          <th>Quantity</th>
          <th>Retail Price</th>
          <th>Wholesale Price</th>
        </tr>
      </thead>
      <tbody>
        <!-- PRODUCT -->
        {{ORDER_ITEMS}}
        <tr class="conclusion">
          <td colspan="3"></td>
          <td>&euro;{{order?.total}}</td>
          <td>&euro;{{order?.totalCost ?? order?.items?.reduce((acc, item) => acc + item?.product?.cost * item?.quantity, 0) }}</td>
        </tr>
      </tbody>
    </table>
  
    <p>Your order will be shipped to the following address:</p>
  
    <p>
      <strong>Name:</strong> {{customer?.fullName ?? 'Not Found'}} <br />
      <strong>Email:</strong> {{customer?.email ?? 'Not Found'}} <br />
      <strong>Address:</strong> {{order?.address?.addressLine1}} {{order?.address?.addressLine2}} <br /> 
      <strong>Phone:</strong> {{order?.address?.phoneNumber}} <br />
      <strong>City:</strong> {{order?.address?.city}} <br />
      <strong>Country:</strong> {{order?.address?.country}} 
    </p>
  
    <p>
      If you have any questions or concerns, please contact us at
      <a href="mailto:matpsaila@gmail.com">matpsaila@gmail.com</a> or call us at <a href="tel:+35621381667">+35621381667</a>.
    </p>
  
    <p>We hope you enjoy your products and thank you for choosing us!</p>
  
    <p>Sincerely,<br /><a href="https://amaseyewear.com">AMAS EYEWEAR</a> team</p>
  </div>
</div>
`;
