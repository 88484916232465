import { useState, useEffect } from "react";
import Compressor from "compressorjs";

import { rgbToHex, hslToRgb } from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ImageService from "services/Entities/ImageService";

const ColorPattern = ({
  object,
  setObject,
  colorOrImage,
  index,
  handleChange,
}: any) => {
  const [chooseStyle, setChooseStyle] = useState<"color" | "image">();

  const [selectedImage, setSelectedImage] = useState(null);

  const imagesClient = new ImageService();

  const convertColor = (color: string) => {
    const trimmedColor = color.trim();

    if (trimmedColor.startsWith("rgb")) {
      return rgbToHex(trimmedColor);
    } else if (trimmedColor.startsWith("hsl")) {
      const rgbColor = hslToRgb(trimmedColor);
      return rgbToHex(rgbColor);
    }

    return trimmedColor;
  };

  const handleStyle = (
    event: React.MouseEvent<HTMLElement>,
    style: "color" | "image"
  ) => {
    setChooseStyle(style);
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    uploadImage(file);
  };

  const uploadImage = async (file: any) => {
    try {
      new Compressor(file, {
        quality: 0.9,
        maxWidth: 50,
        success(result) {
          const formData = new FormData();
          formData.append("file", result);

          imagesClient
            .postSpecificResource("upload", formData)
            .then((response) => {
              const tempObj = structuredClone(object);

              tempObj["variants"][index].colorCss =
                "url('" +
                (process.env.REACT_APP_CDN_URL ||
                  window["REACT_APP_CDN_URL"] ||
                  "https://cdn.amaseyewear.com") +
                "/" +
                response?.data?.data?.path +
                "')";

              setObject(tempObj);
            });
        },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    colorOrImage(object.variants[index].colorCss) === "color"
      ? setChooseStyle("color")
      : setChooseStyle("image");
  }, [object]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <ToggleButtonGroup
          size="large"
          color="primary"
          value={chooseStyle}
          exclusive
          onChange={handleStyle}
          aria-label="Color or Image"
        >
          <ToggleButton value="color">Color</ToggleButton>
          <ToggleButton value="image">Image</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={3}>
        {chooseStyle === "color" ? (
          <TextField
            required
            fullWidth
            type="color"
            label="Color CSS"
            name="colorCss"
            value={
              object
                ? convertColor(object.variants[index].colorCss)
                : "rgb(149,2,4)"
            }
            onChange={(e) => handleChange(e.target, index)}
          />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <input type="file" onChange={handleImageUpload} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ColorPattern;
