import { showErrorAlert } from "./alerts";
import AdminService from "services/Entities/AdminService";

export const REMOVE_USER = "REMOVE_USER_ACTION";
export const SET_USER = "SET_USER_ACTION";

export const unsetUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const getUser = (data: any) => {
  return async (dispatch) => {
    try {
      const adminsClient = new AdminService();
      adminsClient.getSpecificResource("auth/me", {}).then((response) => {
        if (response?.data?.status === "success") {
          const action = {
            type: SET_USER,
            payload: response?.data?.data,
          };

          dispatch(action);
        }
      });
    } catch (e) {
      dispatch(showErrorAlert(e));
    }
  };
};
