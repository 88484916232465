import { useState, useEffect } from "react";

import GenericTable from "components/GenericTable/GenericTable";
import { useApiHook } from "providers/ApiProvider";
import products from "configs/products";

import placeholderPic from "../../../assets/no_data_x.gif";

const ProductsToCategory = ({
  setProductsToAdd,
  productsInCategory,
  loading,
}: any) => {
  const [selectedRows, setSelectedRows] = useState(
    productsInCategory ? productsInCategory : []
  );
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const allProducts = useApiHook("Products", "postSpecificResource", "query", {
    pageQuery: { page: page + 1, size: size },
  });

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  const handlePageChange = (e: any, value: any) => {
    setPage(Number(value));
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setPage(0);
    setSize(Number(e.target.value));
  };

  useEffect(() => {
    setSelectedRows(productsInCategory);
  }, [productsInCategory]);

  useEffect(() => {
    setProductsToAdd(selectedRows);
  }, [selectedRows]);

  return (
    <>
      <GenericTable
        hideActionButton
        list={allProducts?.data?.data ? allProducts?.data?.data : []}
        totalCount={allProducts?.data?.meta?.pagination?.total || 0}
        onSelectedChange={handleRowSelectionChange}
        selectedRows={selectedRows}
        page={page}
        loading={allProducts.loading || loading}
        rowsPerPage={size}
        onRowsChange={handleRowsChange}
        onPageChange={handlePageChange}
        image={placeholderPic}
        tableConfig={products}
        model="product"
        passRow={() => {}}
      />
    </>
  );
};

export default ProductsToCategory;
