import { useActiveUserPermissions } from "hooks/useRoles";

import ProductVariants from "./ProductVariants";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";

import { hexToRgb } from "@mui/material";
import { useApiHook } from "providers/ApiProvider";

const AddEditProductForm = ({
  object,
  setObject,
  formState,
  markupRate,
  colorNames,
  submitResource,
  loading,
}: any) => {
  const currentuserPermissions = useActiveUserPermissions();

  const handleChange = (
    input: any,
    variantIndex?: number,
    variantSpec?: boolean
  ) => {
    const tempObj: any = { ...object };

    const inputValue =
      input.type === "number"
        ? parseFloat(input.value)
        : input.type === "checkbox"
        ? input.checked
        : input.value;

    if (variantIndex !== undefined) {
      if (variantSpec) {
        tempObj.variants[variantIndex].specifications[input.name] = inputValue;
      } else {
        if (input.name === "colorCss") {
          tempObj.variants[variantIndex][input.name] = hexToRgb(inputValue);
        } else {
          tempObj.variants[variantIndex][input.name] = inputValue;
        }
      }
    } else {
      // tempObj[input.name] = inputValue;
      if (input.type === "number") {
        if (input.value === "" || input.value === null || isNaN(input.value)) {
          tempObj[input.name] = "";
        } else {
          tempObj[input.name] = inputValue;
        }
      } else {
        tempObj[input.name] = inputValue;
      }
    }

    setObject({ ...tempObj });
  };

  const handleBrandChange = (value: any) => {
    const tempObj: any = { ...object };

    tempObj.brandName = value;

    setObject({ ...tempObj });
  };

  const handleColorCodeChange = (index) => (event, newValue) => {
    const tempObj: any = { ...object };

    tempObj.variants[index]["colorCode"] = newValue?.key ?? "";

    setObject({ ...tempObj });
  };

  const addVariant = (index: number) => {
    const tempObj = { ...object };

    tempObj.variants.push({
      id: "",
      variantName: `Variant ${index + 1}`,
      // lensDescription: "",
      modelCode: "",
      colorCode: "",
      colorCss: "rgb(151,2,4)",
      colorFamily: "",
      stockValue: 0,
      specifications: {
        lensHeight: 0,
        lensWidth: 0,
        lensDiagonal: 0,
        lensMaterial: "",
        bridgeNodeLengthSize: 0,
        templeLengthSize: 0,
      },
    });

    setObject(tempObj);
  };

  const deleteVariant = (index: number) => {
    const tempObj = { ...object };

    tempObj.variants.splice(index, 1);
    setObject(tempObj);
  };

  const removeImage = (variantIndex: number, imageIndex: number) => {
    let tempObj: any = { ...object };
    tempObj.variants[variantIndex].imageLinks.splice(imageIndex, 1);
    setObject({ ...tempObj });
  };

  const brandData = useApiHook("Brands", "getAllResources", {});

  return (
    <form className="add-product-form" onSubmit={submitResource}>
      {/* Basic Information */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h4"
          color="secondary"
          gutterBottom
          sx={{ textTransform: "uppercase" }}
        >
          General
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={formState === "edit"}
              label="ID"
              name="id"
              InputLabelProps={{ shrink: true }}
              value={object ? object.id : ""}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Model Name"
              name="modelName"
              value={object ? object.modelName : ""}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={brandData?.data?.data ?? []}
              getOptionLabel={(option: any) => option.name ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Brand"
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              value={
                object?.brandName
                  ? brandData?.data?.data?.find(
                      (option) =>
                        option?.name?.toUpperCase() ===
                        object?.brandName?.toUpperCase()
                    )
                  : ""
              }
              onChange={(e: any, v: any) => {
                handleBrandChange(v?.name);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              type="datetime-local"
              label="Release Date"
              name="releaseDate"
              InputLabelProps={{ shrink: true }}
              value={object ? object.releaseDate.slice(0, 16) : ""}
              // onFocus={(e) => {
              //   e.preventDefault();
              //   (e.currentTarget as HTMLInputElement).showPicker();
              // }}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              type="number"
              label="Weight"
              name="weight"
              value={object ? object.weight : 0}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Weight Unit"
              name="weightUnit"
              value={object ? object.weightUnit : ""}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              type="number"
              label="Wholesale Price"
              name="cost"
              value={object ? object.cost : 0}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              type="number"
              label="Price"
              name="price"
              value={object ? object.price : 0}
              onChange={(e) => handleChange(e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              disabled
              fullWidth
              type="text"
              label="Viewing Price"
              name="viewingPrice"
              value={object ? object.price * markupRate : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="bestSeller"
                  checked={object ? object.bestSeller : false}
                  onChange={(e) => handleChange(e.target)}
                />
              }
              label="Best Seller"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Specifications */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h4"
          color="secondary"
          gutterBottom
          sx={{ textTransform: "uppercase" }}
        >
          Specifications
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              select
              label="Spec Segment"
              name="specSegment"
              value={object ? object.specSegment : ""}
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value={"MAN"}>MAN</MenuItem>
              <MenuItem value={"WOMAN"}>WOMAN</MenuItem>
              <MenuItem value={"KIDS"}>KIDS</MenuItem>
              <MenuItem value={"UNISEX"}>UNISEX</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              select
              label="Spec Type"
              name="specType"
              value={object ? object.specType : ""}
              onChange={(e) => handleChange(e.target)}
            >
              <MenuItem value={"SUN"}>Sunglasses</MenuItem>
              <MenuItem value={"EYE"}>Eyeglasses</MenuItem>
              <MenuItem value={"LENS"}>Lenses</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <ProductVariants
        object={object}
        setObject={setObject}
        colorNames={colorNames}
        handleChange={handleChange}
        handleColorCodeChange={handleColorCodeChange}
        addVariant={addVariant}
        deleteVariant={deleteVariant}
        removeImage={removeImage}
      />

      {currentuserPermissions?.includes("edit-product") && (
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ color: "white", mt: "30px", width: "100%" }}
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size="1rem" />
              ) : formState === "create" ? (
                "Add Product"
              ) : (
                "Update Product"
              )}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default AddEditProductForm;
