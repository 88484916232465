import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { getOrderTemplate } from "lib/orderHtml";
import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import OrderService from "services/Entities/OrderService";
import CustomerService from "services/Entities/CustomerService";
import ConfigService from "services/Entities/ConfigService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";

import AddEditOrderForm from "../components/AddEditOrderForm";

import "./AddEditOrder.scss";

const AddEditOrder = () => {
  const [formState, setFormState] = useState("create");

  const [object, setObject] = useState({
    status: "",
    customerId: "",
    items: [],
    customer: {
      id: "",
      email: "",
      fullName: "",
      phoneNumber: "",
      status: "",
    },
    address: {
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      state: "",
      city: "",
      country: "",
      phoneNumber: "",
    },
    customerName: "",
    customerEmail: "",
  });

  const [customerDetails, setCustomerDetails] = useState(null);

  const [loading, setLoading] = useState(false);

  const ordersClient = new OrderService();
  const customersClient = new CustomerService();
  const configsClient = new ConfigService();

  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();
  const currentuserPermissions = useActiveUserPermissions();

  const [colorNames, setColorNames] = useState<any>(null);

  const onPrint = async () => {
    try {
      const html = getOrderTemplate({
        order: object,
        customer: customerDetails,
        colorNames: colorNames,
      });
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.onload = () => {
        setTimeout(() => {
          iframe.remove();
        }, 5000);
      };
      if (iframe.contentWindow) {
        const titleElement = document.createElement("title");
        // @ts-ignore
        titleElement.innerText = `AMAS EYEWEAR - Order #${object.id}`;
        iframe.contentWindow.document.head.appendChild(titleElement);
        iframe.contentWindow.document.body.innerHTML = html;
        iframe.contentWindow.print();
      } else {
        iframe.remove();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchColorNames = () => {
    setLoading(true);

    configsClient
      .getColorNames()
      .then((response) => {
        const transformedData = Object.keys(response?.data?.data).map(
          (key) => ({ key, value: response?.data?.data[key] })
        );
        return setColorNames(transformedData);
      })
      .finally(() => setLoading(false));
  };

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-order", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no order to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      ordersClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            history.push("/orders");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    ordersClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        // setObject(res?.data?.data);

        customersClient
          .getClientDetails(res?.data?.data?.customerId, {})
          .then((res2) => {
            setObject({
              ...res?.data?.data,
              customerName: res2?.data?.data?.fullName,
              customerEmail: res2?.data?.data?.email,
            });
            setCustomerDetails(res2?.data?.data);
          })
          .catch((e) => {
            setObject({
              ...res?.data?.data,
              customerName: "Not Found",
              customerEmail: "Not Found",
            });
          });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) fetchSpecificResource();
  }, [id]);

  const submitResource = () => {
    if (formState === "create") {
      if (!permissionChecker("add-order", true)) return;

      setLoading(true);

      const payload = {
        ...object,
      };
      ordersClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            history.push("/orders/");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    } else {
      // if (!permissionChecker("edit-order", true)) return;

      setLoading(true);

      const payload = {
        id: id,
        status: object?.status,
      };
      ordersClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchColorNames();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        loading={loading}
        customButtonsLabels={["Print"]}
        customButtonsHandlers={[() => onPrint()]}
        selectedRows={[{ id }]}
        model="order"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography
            variant="h3"
            sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
          >
            {formState === "create" &&
              currentuserPermissions?.includes("add-order") &&
              "Add "}
            {formState !== "create" &&
              currentuserPermissions?.includes("edit-order") &&
              "Edit "}
            {formState !== "create" &&
              !currentuserPermissions?.includes("edit-order") &&
              "View "}
            Order
          </Typography>

          <AddEditOrderForm
            object={object}
            setObject={setObject}
            colorNames={colorNames}
          />

          {formState === "edit" && (
            // currentuserPermissions?.includes("edit-order") &&
            <Button
              variant="contained"
              onClick={submitResource}
              sx={{ color: "white", mt: "30px" }}
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                "Update Order Status"
              )}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditOrder;
