import Dialog from "@mui/material/Dialog";

import { FaXmark } from "react-icons/fa6";

import "./Modal.scss";

const GenericModal = (props: any) => {
  const {
    state,
    onClose,
    persist = false,
    children,
    size,
    showCloseIcon = true,
  } = props;
  const fullScreen = size === "fullscreen";
  return (
    <Dialog
      classes={{
        paper: "modal " + (size ? size : " "),
      }}
      disableEscapeKeyDown={persist}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "lg"}
      onClose={onClose}
      open={state}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          margin: "10px",
        }}
      >
        {showCloseIcon && <FaXmark className="closeIcon" onClick={onClose} />}
      </div>
      <div style={{ padding: "20px" }}>{children}</div>
    </Dialog>
  );
};

export default GenericModal;
