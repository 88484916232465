import { useEffect, useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import CategoryService from "services/Entities/CategoryService";
import ProductService from "services/Entities/ProductService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";

import AddEditCategoryForm from "../components/AddEditCategoryForm";

import "./AddEditCategory.scss";

const AddEditCategory = () => {
  const [formState, setFormState] = useState("create");

  const [object, setObject] = useState({
    id: "",
    name: "",
    imageLink: "",
  });

  const [loading, setLoading] = useState(false);
  const [productsInCategory, setProductsInCategory] = useState([]);

  const [categoryProducts, setCategoryProducts] = useState([]);
  const [productsToAdd, setProductsToAdd] = useState([]);

  const productsClient = new ProductService();
  const categoriesClient = new CategoryService();

  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();
  const currentuserPermissions = useActiveUserPermissions();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-category", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no categories to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      categoriesClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          }
          history.push("/categories");
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    categoriesClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-category", true)) return;

      setLoading(true);

      const payload = {
        ...object,
      };
      categoriesClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          }
          history.push("/categories");
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-category", true)) return;

      setLoading(true);

      const payload = {
        ...object,
      };

      const currentProductsInCategoryIds: string[] = productsToAdd;

      const originalProductsInCategoryIds = categoryProducts.map(
        (product: any) => product.id
      );

      const prodsToAdd = currentProductsInCategoryIds.filter(
        (id) => !originalProductsInCategoryIds.includes(id)
      );

      const prodsToRemove = originalProductsInCategoryIds.filter(
        (id) => !currentProductsInCategoryIds.includes(id)
      );

      const removeRequest =
        prodsToRemove.length > 0
          ? categoriesClient.postSpecificResource("products/remove", {
              categoryId: id,
              productIds: prodsToRemove,
            })
          : Promise.resolve();

      const addRequest =
        prodsToAdd.length > 0
          ? categoriesClient.postSpecificResource("products/add", {
              categoryId: id,
              productIds: prodsToAdd,
            })
          : Promise.resolve();

      const updateCategoryRequest = categoriesClient.patchResource(payload);

      Promise.all([removeRequest, addRequest, updateCategoryRequest])
        .then(([removeResponse, addResponse, updateResponse]) => {
          if (updateResponse?.data?.status === "success") {
            dispatch(showSuccessAlert("Category updated"));
            fetchProductsInCurrentCategory();
          }
        })
        .catch((error) => {
          dispatch(showErrorAlert(error));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchProductsInCurrentCategory = () => {
    const payload = {
      categoryId: id,
    };
    productsClient
      .postSpecificResource("query", payload)
      .then((response) => {
        setProductsInCategory(
          response.data.data.map((product: any) => product.id)
        );
        setCategoryProducts(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchSpecificResource();
      fetchProductsInCurrentCategory();
    }
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        selectedRows={[{ id }]}
        model="category"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography
            variant="h3"
            sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
          >
            {formState === "create" &&
              currentuserPermissions?.includes("add-category") &&
              "Add "}
            {formState !== "create" &&
              currentuserPermissions?.includes("edit-category") &&
              "Edit "}
            {formState !== "create" &&
              !currentuserPermissions?.includes("edit-category") &&
              "View "}
            Category
          </Typography>

          <AddEditCategoryForm
            object={object}
            setObject={setObject}
            setProductsToAdd={setProductsToAdd}
            productsInCategory={productsInCategory}
            loading={loading}
            formState={formState}
            submitResource={submitResource}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditCategory;
