import IconButton from "@mui/material/IconButton/IconButton";

import { FaXmark } from "react-icons/fa6";

import "./DisplayImages.scss";

interface DisplayImagesInterface {
  brand?: boolean;
  imageLinks: string[];
  variantIndex?: number;
  removeImage: any;
}

const RenderImage = ({
  index,
  variantIndex,
  imageLink,
  brand,
  removeImage,
}: any) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img
        src={
          (process.env.REACT_APP_CDN_URL ??
            window["REACT_APP_CDN_URL"] ??
            "https://cdn.amaseyewear.com") +
          "/" +
          imageLink
        }
        className="uploaded-image"
      />
      <IconButton
        disableRipple
        color="primary"
        onClick={() => {
          if (brand) {
            removeImage();
          } else {
            removeImage(variantIndex, index);
          }
        }}
      >
        <FaXmark fontSize={16} />
      </IconButton>
    </div>
  );
};

const DisplayImages = ({
  brand = false,
  imageLinks,
  variantIndex,
  removeImage,
}: DisplayImagesInterface) => {
  return (
    <div className="display-image-container">
      {imageLinks &&
        imageLinks.length > 0 &&
        imageLinks.map((imageLink: any, index: number) => {
          return (
            <RenderImage
              key={index}
              index={index}
              brand={brand}
              variantIndex={variantIndex}
              imageLink={imageLink}
              removeImage={removeImage}
            />
          );
        })}
    </div>
  );
};

export default DisplayImages;
