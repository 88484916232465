import { TableConfig } from "interfaces/tableConfig";

const admins: TableConfig[] = [
  {
    heading: "ID",
    attribute: "id",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Email",
    attribute: "email",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Super Admin",
    attribute: "super",
    type: "boolean",
    sortable: true,
    selected: true,
  },
];

export default admins;
