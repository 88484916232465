import { useSelector } from "react-redux";
import { User } from "../interfaces/Users";

const useUserData = (): User => {
  const selectStoreAlerts = (state) => state.user;

  return useSelector(selectStoreAlerts);
};

export default useUserData;
