export const colors = {
  black: "#162133",
  lightBlack: "#151521",
  lighterBlack: "#1b1b2c",
  lightestBlack: "#27273F",
  borderColor: "rgba(0, 0, 0, 0.1)",
  lighterBlackBorder: "#3e3946",
  lightBlackBorder: "#666",
  blackTooltip: "#1b1b25",
  darkGrey: "#808080",
  blue: "#017aff",
  green: "#08a441",
  red: "#b52a00",
  cyan: "#00AEB9",
  offWhite: "#eff4f8c7",
  purple: "#770fc8",
  purpleLight: "#a946f6",
  white: "#ffffff",
  lightWhite: "#fafafa",
};

const theme = () => {
  return {
    palette: {
      background: {
        default: colors.offWhite,
        paper: colors.white,
      },
      grid: {
        main: colors.white,
        content: colors.offWhite,
      },
      primary: {
        main: "hsl(359, 98%, 30%)",
      },
      secondary: {
        main: "#162133",
      },
      text: {
        primary: colors.black,
      },
    },
    typography: {
      fontFamily: "Poppins",
      h1: {
        fontSize: "32px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.21,
        letterSpacing: "1.25px",
        "@media (max-width:600px)": {
          fontSize: "21px",
        },
      },
      h2: {
        fontSize: "28px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.21,
        letterSpacing: "1.25px",
        "@media (max-width:600px)": {
          fontSize: "18px",
        },
      },
      h3: {
        fontSize: "24px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.24,
        letterSpacing: "0.8px",
      },
      h4: {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: 1.31,
        letterSpacing: "0.61px",
      },
      h5: {
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",
        lineHeight: 1.5,
        letterSpacing: "0.53px",
      },
      subtitle1: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: 1.45,
        letterSpacing: ".42px",
        textTransform: "uppercase",
        opacity: "0.6",
      },
      subtitle2: {
        fontSize: "12px",
        fontWeight: 500,
        textTransform: "capitalize",
        lineHeight: 1.08,
        letterSpacing: "0.46px",
      },
      h6: {
        fontSize: "14px",
        fontWeight: 600,
        textTransform: "capitalize",
        lineHeight: 1.43,
        letterSpacing: "0.53px",
      },
      caption: {
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "capitalize",
        lineHeight: 1.43,
        letterSpacing: "0.53px",
      },
      body1: {
        fontSize: "16px",
        fontWeight: 500,
        letterSpacing: "0.61px",
        lineHeight: 1.31,
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "14px",
          color: colors.white,
          backgroundColor: colors.blackTooltip,
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: false,
      },
    },
  };
};

export default theme;
