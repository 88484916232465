import { TableConfig } from "interfaces/tableConfig";

const categories: TableConfig[] = [
  {
    heading: "ID",
    attribute: "id",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Name",
    attribute: "name",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Image",
    attribute: "imageLink",
    type: "avatar",
    sortable: false,
    selected: true,
  },
];

export default categories;
