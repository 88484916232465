import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import OrderItems from "./OrderItems";

const AddEditOrderForm = ({ object, setObject, colorNames }: any) => {
  const handleChange = (input: any) => {
    let tempObj: any = { ...object };
    tempObj["status"] = input.target.value;
    setObject({ ...tempObj });
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            className="label-heading"
            variant="h4"
            color="secondary"
            gutterBottom
            sx={{ textTransform: "uppercase" }}
          >
            Order Status
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Status"
            name="status"
            select
            sx={{
              width: { xs: 150, sm: 300 },
            }}
            value={object ? object.status : "complete"}
            onChange={handleChange}
          >
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
            <MenuItem value={"complete"}>Complete</MenuItem>
            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Typography
            className="label-heading"
            variant="h4"
            color="secondary"
            gutterBottom
            sx={{ textTransform: "uppercase" }}
          >
            Order Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h4">
            Order ID: {object ? object.id : ""}
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <OrderItems
            items={object?.items || []}
            totalRetailPrice={object?.total || 0}
            totalWholesalePrice={
              object?.totalCost ??
              object?.items.reduce(
                (acc, item) => acc + item.product.cost * item.quantity,
                0
              )
            }
            colorNames={colorNames}
          />
        </Grid>

        {/* <Grid item xs={12} mt={2}>
          <Box
            sx={{
              background: "red",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="h4" color="primary">
              Total:
            </Typography>
            <Typography variant="h4">€{object?.total}</Typography>
          </Box>
        </Grid> */}

        <Grid item xs={12} mt={5}>
          <Typography
            className="label-heading"
            variant="h4"
            color="secondary"
            gutterBottom
            sx={{ textTransform: "uppercase" }}
          >
            Customer Details
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Full Name"
            name="name"
            value={
              object ? object?.customer?.fullName ?? object.customerName : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Email"
            name="email"
            value={
              object ? object?.customer?.email ?? object.customerEmail : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            label="Address Line 1"
            name="addressLine1"
            value={object ? object.address.addressLine1 : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            label="Address Line 2"
            name="addressLine1"
            value={object ? object.address.addressLine2 : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="City"
            name="city"
            value={object ? object.address.city : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Country"
            name="country"
            value={object ? object.address.country : ""}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <TextField
            disabled
            fullWidth
            label="State"
            name="state"
            value={object ? object.address.state : ""}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Postal Code"
            name="postalCode"
            value={object ? object.address.postalCode : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            value={object ? object.address.phoneNumber : ""}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEditOrderForm;
