import { useActiveUserPermissions } from "hooks/useRoles";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import DisplayImages from "components/DisplayImages/DisplayImages";
import ImageDropzone from "components/ImageDropzone/ImageDropzone";
import ProductsToCategory from "./ProductsToCategory";

const AddEditCategoryForm = ({
  object,
  setObject,
  setProductsToAdd,
  productsInCategory,
  loading,
  formState,
  submitResource,
}: any) => {
  const currentuserPermissions = useActiveUserPermissions();

  const handleChange = (input: any) => {
    let tempObj: any = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  const removeImage = (index: number) => {
    let tempObj: any = object;
    tempObj["imageLink"] = "";
    setObject({ ...tempObj });
  };

  return (
    <form onSubmit={submitResource}>
      <TextField
        required
        fullWidth
        label="Name"
        name="name"
        value={object ? object.name : ""}
        onChange={(e) => handleChange(e.target)}
        className="mt-2"
      />

      <Box mt={3}></Box>

      {object?.imageLink ? (
        <DisplayImages
          imageLinks={[object.imageLink]}
          removeImage={removeImage}
          brand={true}
        />
      ) : null}

      <Box mt={5} mb={5}>
        <ImageDropzone
          multiple={false}
          entity={"category"}
          object={object}
          setObject={setObject}
        />
      </Box>

      {formState === "edit" && (
        <>
          <Typography
            variant="h4"
            className="heading"
            color="secondary"
            gutterBottom
            sx={{ textTransform: "uppercase" }}
          >
            Add Products to Category
          </Typography>
          <ProductsToCategory
            setProductsToAdd={setProductsToAdd}
            productsInCategory={productsInCategory}
            loading={loading}
          />
        </>
      )}

      {currentuserPermissions?.includes("edit-category") && (
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button
            variant="contained"
            sx={{ color: "white", mt: "30px", width: "100%" }}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="1rem" />
            ) : formState === "create" ? (
              "Create Category"
            ) : (
              "Update Category"
            )}
          </Button>
        </Box>
      )}
    </form>
  );
};

export default AddEditCategoryForm;
