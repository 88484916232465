import { useEffect, useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import CustomerService from "services/Entities/CustomerService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditCustomerForm from "../components/AddEditCustomerForm";

import "./AddEditCustomer.scss";

const AddEditCustomer = () => {
  const [formState, setFormState] = useState("create");

  const [object, setObject] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      country: "",
      phoneNumber: "",
    },
    status: "inactive",
  });

  const [loading, setLoading] = useState(false);
  const customersClient = new CustomerService();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();
  const currentuserPermissions = useActiveUserPermissions();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-customer", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no customer to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      customersClient
        .deleteResource(payload)
        .then((res) => {
          history.push("/customers");
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    customersClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-admin", true)) return;

      if (object?.password !== object?.confirmPassword) {
        dispatch(
          showErrorAlert({
            message: "Password and Confirm Password must be same",
          })
        );
        return;
      }

      setLoading(true);

      const payload = {
        ...object,
      };
      customersClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          history.push("/customers");
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-customer", true)) return;

      if (object?.password !== "" || object?.confirmPassword !== "") {
        if (object?.password !== object?.confirmPassword) {
          dispatch(
            showErrorAlert({
              message: "Password and Confirm Password must be same",
            })
          );
          return;
        }
      }

      setLoading(true);

      const payload = {
        id: id,
        ...object,
      };
      customersClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            setObject({ ...object, password: "", confirmPassword: "" });
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (id) fetchSpecificResource();
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        selectedRows={[{ id }]}
        model="customer"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography
            variant="h3"
            sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
          >
            {formState === "create" &&
              currentuserPermissions?.includes("add-customer") &&
              "Add "}
            {formState !== "create" &&
              currentuserPermissions?.includes("edit-customer") &&
              "Edit "}
            {formState !== "create" &&
              !currentuserPermissions?.includes("edit-customer") &&
              "View "}
            Customer
          </Typography>

          <AddEditCustomerForm
            object={object}
            setObject={setObject}
            submitResource={submitResource}
            formState={formState}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditCustomer;
