import { TableConfig } from "interfaces/tableConfig";

const brands: TableConfig[] = [
  {
    heading: "Code",
    attribute: "code",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Name",
    attribute: "name",
    type: "string",
    sortable: true,
    selected: true,
  },
  {
    heading: "Logo",
    attribute: "imageLink",
    type: "avatar",
    sortable: false,
    selected: true,
  },
];

export default brands;
