import ApiRequests from "../apiRequests";

class CustomerService extends ApiRequests {
  constructor() {
    super("customers");
  }

  async getClientDetails(
    id: string,
    payload: any,
    abortController = new AbortController()
  ): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}/${id}`,
      payload,
      "GET",
      {
        signal: abortController.signal,
      },
      true
    );
  }
}

export default CustomerService;
