import { useEffect, useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import AdminService from "services/Entities/AdminService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditAdminForm from "../components/AddEditAdminForm";

import "./AddEditAdmin.scss";

const AddEditAdmin = () => {
  const currentuserPermissions = useActiveUserPermissions();

  const [formState, setFormState] = useState("create");
  const [object, setObject] = useState({
    super: false,
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [adminRole, setAdminRole] = useState("");
  const [loading, setLoading] = useState(false);
  const adminsClient = new AdminService();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-admin", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no admin to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      adminsClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          history.push("/admins");
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    adminsClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res?.data?.data);
        setAdminRole(res?.data?.data?.super ? "superAdmin" : "admin");
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-admin", true)) return;

      if (object?.password !== object?.confirmPassword) {
        dispatch(
          showErrorAlert({
            message: "Password and Confirm Password must be same",
          })
        );
        return;
      }

      setLoading(true);

      const payload = {
        ...object,
        super: adminRole === "superAdmin" ? true : false,
      };

      adminsClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          history.push("/admins");
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-admin", true)) return;

      if (object?.password !== "" || object?.confirmPassword !== "") {
        if (object?.password !== object?.confirmPassword) {
          dispatch(
            showErrorAlert({
              message: "Password and Confirm Password must be same",
            })
          );
          return;
        }
      }

      setLoading(true);

      const payload = {
        id: id,
        ...object,
        super: adminRole === "superAdmin" ? true : false,
      };

      adminsClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            setObject({ ...object, password: "", confirmPassword: "" });
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (id) fetchSpecificResource();
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        selectedRows={[{ id }]}
        model="admin"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography
            variant="h3"
            sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
          >
            {formState === "create" &&
              currentuserPermissions?.includes("add-admin") &&
              "Add "}
            {formState !== "create" &&
              currentuserPermissions?.includes("edit-admin") &&
              "Edit "}
            {formState !== "create" &&
              !currentuserPermissions?.includes("edit-admin") &&
              "View "}
            Admin
          </Typography>

          <AddEditAdminForm
            object={object}
            setObject={setObject}
            adminRole={adminRole}
            setAdminRole={setAdminRole}
            submitResource={submitResource}
            formState={formState}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditAdmin;
