import { ApiResponses, OnModelsUpdateArguments } from "../types";

export default function getResponsesToUpdate(
  responses: ApiResponses,
  { modelKey, models: _models, action }: OnModelsUpdateArguments
): ApiResponses {
  const _modelsIds = _models.map((m) => m?._id);
  const responsesToUpdate = Object.entries(responses)
    // if the response has a modelKey, and equals the update model's key
    .filter(([, response]) => response.modelKey === modelKey)
    .filter(([, response]) => {
      return (
        action === "create" ||
        response.modelIds?.some((id) => _modelsIds.includes(id))
      );
    });

  return Object.fromEntries(responsesToUpdate);
}
