import { useEffect, useState, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import BrandService from "services/Entities/BrandService";
import {
  usePermissionChecker,
  useActiveUserPermissions,
} from "../../../hooks/useRoles";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";

import AddEditBrandForm from "../components/AddEditBrandForm";

import "./AddEditBrand.scss";

const AddEditBrand = () => {
  const [formState, setFormState] = useState("create");

  const [object, setObject] = useState({
    code: "",
    name: "",
    imageLink: "",
  });

  const [loading, setLoading] = useState(false);
  const brandsClient = new BrandService();
  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();
  const currentuserPermissions = useActiveUserPermissions();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-customer", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no brands to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
      };
      brandsClient
        .deleteResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            history.push("/brands");
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    brandsClient
      .getSpecificResource(id, {})
      .then((res) => {
        setFormState("edit");
        setObject(res?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const submitResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === "create") {
      if (!permissionChecker("add-brand", true)) return;

      setLoading(true);

      const payload = {
        ...object,
      };
      brandsClient
        .postResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
            window.location.href = "/brands";
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-brand", true)) return;

      setLoading(true);

      const payload = {
        id: id,
        ...object,
      };
      brandsClient
        .patchResource(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            dispatch(showSuccessAlert(res?.data?.message));
          } else {
            dispatch(showErrorAlert(res?.data?.message));
          }
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (id) fetchSpecificResource();
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={
          formState === "edit" ? deleteResourceHandler : undefined
        }
        selectedRows={[{ id }]}
        model="brand"
        showBackButton
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography
            variant="h3"
            sx={{ color: "hsl(359, 98%, 30%)", mb: "30px" }}
          >
            {formState === "create" &&
              currentuserPermissions?.includes("add-brand") &&
              "Add "}
            {formState !== "create" &&
              currentuserPermissions?.includes("edit-brand") &&
              "Edit "}
            {formState !== "create" &&
              !currentuserPermissions?.includes("edit-brand") &&
              "View "}
            Brand
          </Typography>

          <AddEditBrandForm
            object={object}
            setObject={setObject}
            submitResource={submitResource}
            formState={formState}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditBrand;
