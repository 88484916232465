import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingIndicator = () => (
  <Box
    sx={{
      inset: 1,
      height: "100vh",
      display: "grid",
      placeItems: "center",
      position: "absolute",
      zIndex: -1,
    }}
  >
    <CircularProgress size={"50px"} />
  </Box>
);

export default LoadingIndicator;
