import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

import getVariantBackground from "lib/getVariantBackground";

const OrderItems = ({
  items,
  totalRetailPrice,
  totalWholesalePrice,
  colorNames,
}: any) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Product</strong>
              </TableCell>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Variant</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Quantity</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Retail Price</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Wholesale Price</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: any, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell width={200} align="left">
                  <img
                    style={{
                      maxWidth: "150px",
                      height: "75px",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                    src={
                      (process.env.REACT_APP_CDN_URL ??
                        window["REACT_APP_CDN_URL"] ??
                        "https://cdn.amaseyewear.com") +
                      "/" +
                      item?.product?.variants?.[0]?.imageLinks[0]
                    }
                  />
                </TableCell>
                <TableCell>
                  <a
                    href={
                      (process.env.REACT_APP_SITE_URL ??
                        window["REACT_APP_SITE_URL"] ??
                        "https://amaseyewear.com") +
                      "/" +
                      "products/" +
                      item?.product?.id +
                      "?variant=" +
                      item?.product?.variants?.[0]?.id
                    }
                    target="_blank"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography color="primary">
                      {item.product.modelName}
                    </Typography>
                  </a>
                </TableCell>
                <TableCell>
                  <span
                    style={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      display: "inline-block",
                      background:
                        getVariantBackground(
                          item?.product?.variants?.[0]?.colorCss
                        ) + " center center / cover",
                    }}
                  ></span>
                  &nbsp;
                  <span>
                    {item?.product?.variants?.[0]?.colorCode
                      ? `${item?.product?.variants?.[0]?.colorCode}/${
                          colorNames?.find(
                            (option) =>
                              option.key ===
                              item?.product?.variants?.[0]?.colorCode
                          )?.value || ""
                        }`
                      : item?.product?.variants?.[0]?.variantName}
                  </span>
                </TableCell>
                <TableCell align="center">{item?.quantity}</TableCell>
                <TableCell align="center">
                  € {item?.product?.price * item?.quantity}
                  {item?.quantity > 1 && (
                    <>
                      <br />
                      <small style={{ color: "#a0a0a0", fontSize: "10px" }}>
                        ({item?.product?.price} x {item?.quantity})
                      </small>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  € {item?.product?.cost * item?.quantity}
                  {item?.quantity > 1 && (
                    <>
                      <br />
                      <small style={{ color: "#a0a0a0", fontSize: "10px" }}>
                        ({item?.product?.cost} x {item?.quantity})
                      </small>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell align="center">
                <Typography variant="h4" color="primary">
                  TOTAL
                </Typography>
              </TableCell>
              <TableCell align="center">
                <strong>€ {totalRetailPrice}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>€ {totalWholesalePrice}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderItems;
